<script>
import PersonalDetails from './PersonalDetails.vue'
import AdditionalDetails from './AdditionalDetails.vue'
import AddressDetails from './AddressAndBackgroundDetails.vue'
import CommonRegistration from '../../common/CommonRegistration.vue'
import { PRODUCT_TYPE } from '@/const'
import { mapActions, mapState } from 'pinia'
import { useAppStore } from '@/store/app.store'
import stepsConfig from '@/views/ee/steps.config'

export default {
  components: {
    PersonalDetails,
    AdditionalDetails,
    AddressDetails
  },
  extends: CommonRegistration,
  computed: {
    ...mapState(useAppStore, ['featureFlags']),
    views () {
      return stepsConfig.currentAccount
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setProductType', 'getStatus'])
  },
  async created () {
    await this.setProductType(PRODUCT_TYPE.CurrentAccount)
    const status = await this.getStatus()
    if (!status.isAuthenticated) {
      document.location = '/login'
    }
  }
}
</script>
