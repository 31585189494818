<template>
  <common-layout>
    <div class="error-page" data-testid="error-page">
      <bb-error-page class="p-0" v-bind="errorProps" />
      <div class="f-color-gray-80 f-tiny correlation-id" v-if="correlationId">
        {{translations.errCodeInfo}} <strong>{{correlationId}}</strong>
      </div>
    </div>
  </common-layout>
</template>

<script>
import * as app from '@/store/app.store'
import { mapState } from 'pinia'
import CommonLayout from './../components/CommonLayout.vue'
import { BbErrorPage } from '@bigbank/interface-components'

import '@bigbank/interface-components/dist/svg/illustration/error500'
import '@bigbank/interface-components/dist/svg/illustration/error404'
import '@bigbank/interface-components/dist/svg/illustration/maintenance'

export default {
  components: {
    CommonLayout,
    BbErrorPage
  },
  computed: {
    ...mapState(app.useAppStore, ['error', 'channelConfig']),
    errorsList () {
      return {
        500: {
          icon: 'illustration-error500',
          title: this.$pgettext('error_view', 'Sorry ... it\'s not you. It\'s us.'),
          description: this.$pgettext('error_view', 'We are experiencing an internal server problem. Please try again later.'),
          buttonText: this.$pgettext('error_view', 'Back to home'),
          buttonPath: '/'
        },
        404: {
          icon: 'illustration-error404',
          title: this.$pgettext('error_view', 'Oops, we couldn\'t find what you are looking for.'),
          description: this.$pgettext('error_view', 'You may have mistyped the address or the page may have moved.'),
          buttonText: this.$pgettext('error_view', 'Back to home'),
          buttonPath: '/'
        },
        '404-current-account': {
          icon: 'illustration-error404',
          title: this.$pgettext('error_view', 'Oops, we couldn\'t find what you are looking for.'),
          description: this.$pgettext('error_view', 'You may have mistyped the address or the page may have moved.'),
          buttonText: this.$pgettext('error_view', 'Back to home'),
          buttonPath: this.channelConfig.selfserviceUrl
        },
        maintenance: {
          icon: 'illustration-maintenance',
          title: this.$pgettext('error_view', 'We are working to improve your experience.'),
          description: this.$pgettext('error_view', 'Bigbank is currently unavailable as we build up our infrastructure in order to provide better service to all of our clients. Please try back later. Thank you for your patience!')
        }
      }
    },
    errorProps () {
      return this.errorsList[(this.error && this.error.code) || 500]
    },
    translations () {
      return {
        errCodeInfo: this.$pgettext('error_code_info', 'Please try again and if this problem persists, please contact us and provide the following error code:')
      }
    },
    correlationId () {
      return this.error && this.error.correlationId
    }
  }
}
</script>

<style lang="scss" scoped>
.error-page {
  max-width: 600px;
  width: 80%;
  margin: auto;

  .correlation-id {
    text-align: center;
    margin-top: 50px;
  }
}
</style>
