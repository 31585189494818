<template>
  <common-layout
      :progressBar="{
      max: 3,
      value: currentStep + 1,
      color: 'blue',
    }"
  >
    <router-view />
  </common-layout>
</template>

<script>
import { mapState } from 'pinia'
import RoutingMixin from '../common/RoutingMixin.vue'
import CommonLayout from './../../components/CommonLayout.vue'
import { useAppStore } from '@/store/app.store'

export default {
  mixins: [
    RoutingMixin
  ],
  components: {
    CommonLayout
  },
  computed: {
    ...mapState(useAppStore, ['currentStep'])
  }
}
</script>
