<template>
  <thank-you :button-to-url="buttonToUrl" />
</template>

<script>
import ThankYou from '@/views/common/ThankYou.vue'

export default {
  name: 'fi-thank-you',
  components: { ThankYou },
  data () {
    return {
      buttonToUrl: 'https://bigbank.fi'
    }
  }
}
</script>
