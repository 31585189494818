<template>
  <personal-details
    :field-definitions="fieldDefinitions"
    :phone-number-prefix="phoneNumberPrefix"
    :phone-number-prefix-choice="phoneNumberPrefixChoice"
    :channel="channel"
    :locale="language"
    :update-form-data="updateFormData"
    :go-to-next-step="goToNextStep"
    :personal-identity-code-mask="personalIdentityCodeMask"
  ></personal-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import PersonalDetails from '@/views/common/PersonalDetails.vue'
import { cleanInput } from '@/views/common/util'
import { EEPersonalId } from '@bigbank/dc-common/validators/ee/EEPersonalId.class'
import errorHandler from '@/errors/errorHandler'
import { useAppStore } from '@/store/app.store'

const eePhoneValidator = require('@bigbank/dc-common/validators/ee/phoneNumber')

export default {
  name: 'personal-details-ee-private',
  components: {
    PersonalDetails
  },
  data () {
    return {
      isLoading: true,
      phoneNumberPrefix: '+372',
      phoneNumberPrefixChoice: ['+370', '+372', '+371'],
      personalIdentityCodeMask: '11111111111',
      hasAcademicTitle: false,
      marketingConsentVisible: false,
      fieldDefinitions: [
        { name: 'firstName' },
        { name: 'surname' },
        {
          name: 'personalIdentityCode',
          validator: {
            message: this.$pgettext(
              'registration',
              'Please insert valid ID number'
            ),
            validate: (value) => new EEPersonalId(value).isValid()
          }
        },
        { name: 'email' },
        {
          name: 'phoneNumber',
          validator: {
            message: this.$pgettext(
              'registration',
              'Please insert valid phone number'
            ),
            validate: (value) =>
              eePhoneValidator(cleanInput(value), this.channel)
          }
        },
        { name: 'formConfirmationText' },
        { name: 'marketingConsent' },
        { name: 'dataProcessingSilentConsent' }
      ]
    }
  },
  async created () {
    try {
      this.fillPhoneNumberPrefixes()
    } catch (err) {
      await errorHandler(err)
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language', 'status', 'countries'])
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep', 'getCountries']),
    async fillPhoneNumberPrefixes () {
      await this.getCountries()

      this.phoneNumberPrefixChoice = this.countries
        .filter((country) => country.areaCodeAllowed)
        .map((country) => country.areaCode.replace(' ', ''))
        .sort((a, b) => (a > b ? 1 : -1))
      this.phoneNumberPrefixChoice = Array.from(
        new Set(this.phoneNumberPrefixChoice)
      )
    }
  }
}
</script>
