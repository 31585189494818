<template>
  <additional-details
      :field-definitions="fieldDefinitions"
      :initial-values="calculatedInitialValues"
      :date-format="dateFormat"
      :channel="channel"
      :locale="language"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
      :is-last-step="false"
  />
</template>

<script>
import dayjs from 'dayjs'
import { convertIdCodeToShortFormat } from '@bigbank/dc-common/validators/se/idNumber'
import { mapActions, mapState } from 'pinia'
import AdditionalDetails from '@/views/common/AdditionalDetails.vue'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'additional-details-se',
  components: {
    AdditionalDetails
  },
  data () {
    return {
      isLoading: true,
      dateFormat: 'YYYY-MM-DD',
      fieldDefinitions: [
        { name: 'fieldOfActivity' },
        { name: 'customFieldOfActivity' },
        { name: 'placeOfBirth' },
        { name: 'taxResidency' },
        {
          name: 'taxIdNumber',
          showField: form => {
            return form.taxResidency !== this.channel
          }
        },
        { name: 'citizenship' },
        { name: 'dualCitizenship' },
        { name: 'isPep' },
        {
          name: 'isPepReason',
          showField: form => {
            return form.isPep === 'YES'
          }
        }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language', 'formData']),
    calculatedInitialValues () {
      return [
        { name: 'dateOfBirth', value: this.extractDateOfBirth(this.formData.personalIdentityCode), hidden: true }
      ]
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep']),
    extractDateOfBirth (personalIdentityCode) {
      if (personalIdentityCode) {
        const shortIdCode = convertIdCodeToShortFormat(personalIdentityCode)
        const yearSuffix = shortIdCode.slice(0, 2)
        const month = shortIdCode.slice(2, 4)
        const day = shortIdCode.slice(4, 6)
        const personIsOver100 = shortIdCode.charAt(6) === '+'

        const birthDate = dayjs(`${day}.${month}.${yearSuffix}`, 'DD.MM.YY')

        if (personIsOver100) {
          birthDate.subtract(100, 'year')
        }

        return birthDate.format(this.dateFormat)
      }

      return undefined
    }
  }
}
</script>
