<template>
  <thank-you-wrapper v-if="showThankYouContent">
    <template #title>
      <bb-public-header
        :title="translations.title"
        icon="client-success"
      />
    </template>
    <template #text v-if="isCustomerIdentificationRequired">
      <p class="thank-you__text m-t-20">{{ translations.textIdentificationNeeded }}</p>
      <br/>
      <bb-step-list :steps="[
        translations.ourOfficeLocated,
        translations.ourOfficeOpenTime,
        translations.pleaseTakeYourIdentificationDocument]" :active="3"></bb-step-list>
    </template>
    <template #text v-else>
      <p class="thank-you__text m-t-20">{{ translations.text }}</p>
    </template>
    <template #button>
      <bb-button
        display="block"
        :label="translations.backButtonLabel"
        :to="buttonToUrl"
        class="m-t-100"
      />
    </template>
  </thank-you-wrapper>
  <bb-loading-overlay
    v-else-if="!isLoading"
    :loadingDelay="0"
    :is-loading="true"
    color="white"
  />
</template>

<script>
import { BbPublicHeader, BbStepList, BbButton, BbLoadingOverlay } from '@bigbank/interface-components'
import { useAppStore } from '@/store/app.store'
import ThankYouWrapper from '@/components/ThankYouWrapper.vue'
import '@bigbank/interface-components/dist/svg/client/success'
import { mapState } from 'pinia'

export default {
  name: 'thank-you',
  components: {
    BbPublicHeader,
    ThankYouWrapper,
    BbStepList,
    BbButton,
    BbLoadingOverlay
  },
  props: {
    buttonToUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(useAppStore, ['status', 'constants', 'isCustomerIdentificationRequired', 'isV3Flow', 'isV2Flow', 'isLoading']),
    translations () {
      return {
        title: this.$pgettext('thank-you', 'Thank You for signing up!'),
        text: this.$pgettext('thank-you', 'We will get in touch with you soon!'),
        textIdentificationNeeded: this.$pgettext('thank-you', 'To complete Your registration, please come to our office for physical identification.'),
        ourOfficeLocated: this.$pgettext('thank-you', 'Office is located in Tallinn'),
        ourOfficeOpenTime: this.$pgettext('thank-you', 'Office is open weekdays 10.00–15.00'),
        pleaseTakeYourIdentificationDocument: this.$pgettext('thank-you', 'Please take your identification document with you'),
        backButtonLabel: this.$pgettext('thank-you', 'Back to homepage')
      }
    },
    showThankYouContent () {
      const isV1Flow = !this.isV3Flow && !this.isV2Flow
      return isV1Flow || this.status?.status !== this.constants.REGISTRATION_STATUSES.COMPLETED
    }
  }
}
</script>

<style lang="scss" scoped>
.thank-you {
  &__text {
    text-align: center;
  }
}
</style>
