/* eslint-disable @typescript-eslint/no-explicit-any */
// Should type when we have zod or/and shared types between BE and FE
import RequestError from './errors/RequestError';
class API {
    state;
    constructor() {
        this.state = {
            loading: false
        };
    }
    trackEvent(metadata) {
        return request('/api/analytics-event', {
            method: 'post',
            body: JSON.stringify(metadata)
        });
    }
    register(form) {
        return request('/api/register', {
            method: 'post',
            body: JSON.stringify(form)
        });
    }
    registerStep(form, hCaptchaToken) {
        return request('/api/register/step', {
            method: 'post',
            headers: {
                'X-HCaptcha-Token': hCaptchaToken
            },
            body: JSON.stringify(form)
        }).catch(() => null);
    }
    status(params = {}) {
        return request('/api/status', {
            method: 'put',
            body: JSON.stringify(params)
        });
    }
    getIdentificationData() {
        return request('/api/identification-data', {
            method: 'get'
        });
    }
    refresh() {
        return request('/api/refresh', {
            method: 'get'
        });
    }
    saveExternalParameters(parameters) {
        return request('/api/external-parameters', {
            method: 'put',
            body: JSON.stringify(parameters)
        });
    }
    getCountries() {
        return request('/api/meta/countries', {
            method: 'get'
        });
    }
    getPostcode(address) {
        return request('/api/loan-origination/postal-code', {
            method: 'post',
            body: JSON.stringify(address)
        }).catch(() => null);
    }
    getLookupValuesByCode(lookupCode) {
        return request(`/api/account/lookup/${lookupCode}`, {
            method: 'get'
        });
    }
    setLanguage(lang) {
        return request('/settings/language', {
            method: 'put',
            body: JSON.stringify({ lang })
        });
    }
    getSsoStatus() {
        const url = window.LOGIN_SERVICE_URL + '/v2/login/check';
        let status;
        return window.fetch(url, Object.assign({
            method: 'get',
            credentials: 'include'
        })).then(res => {
            status = res.status;
            return res.json();
        }).then(data => {
            return {
                status,
                data
            };
        });
    }
    initConfirmRegistrationData() {
        return request('/api/confirm-registration-data/sign/init', {
            method: 'put'
        });
    }
    startSmartIdSigning(signingRequestId) {
        return request(`/api/confirm-registration-data/sign/smart-id?signingRequestId=${signingRequestId}`, {
            method: 'put'
        });
    }
    completeSmartIdSigning(signingRequestId) {
        return request(`/api/confirm-registration-data/sign/smart-id/complete?signingRequestId=${signingRequestId}`, {
            method: 'put'
        });
    }
    startEparakstsSigning(signingRequestId, redirectUrl) {
        return request(`/api/confirm-registration-data/sign/eparaksts?signingRequestId=${signingRequestId}&redirectUrl=${encodeURIComponent(redirectUrl)}`, {
            method: 'PUT'
        });
    }
    completeEparakstsSigning(signingRequestId) {
        return request(`/api/confirm-registration-data/sign/eparaksts/complete?signingRequestId=${signingRequestId}`, {
            method: 'PUT'
        });
    }
}
export default new API();
function request(url, opts = {}) {
    let status = null;
    let statusCode;
    let headers;
    const requestHeaders = {
        'content-type': 'application/json',
        ...(opts.headers ?? {})
    };
    return window.fetch(url, Object.assign({
        method: 'get',
        credentials: 'include'
    }, opts, {
        headers: requestHeaders
    })).then(res => {
        headers = res.headers;
        statusCode = res.status;
        if (res.ok) {
            status = true;
            return res.json();
        }
        else {
            status = false;
            return res.json();
        }
    }).then(json => {
        if (status)
            return json;
        throw json;
    }).catch((errObj) => {
        const errParams = {
            statusCode,
            correlationId: headers && headers.get('x-correlation-id'),
            data: undefined
        };
        if (errObj && errObj.constructor.name !== 'TypeError') {
            errParams.data = errObj;
        }
        normalErrorHandler(errParams);
    });
}
function normalErrorHandler(err) {
    const errParams = {
        type: (err.data && err.data.err_code) || 'REQUEST_FAILED',
        correlationId: err.correlationId,
        data: err.data
    };
    throw new RequestError(err.message, errParams);
}
