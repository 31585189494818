<template>
  <bb-offer-thank-you-page
    ref="offer"
    :form-props="{ submitText: error.buttonText }"
    @submit="onClick"
  >
    <template #header>
      <bb-hero
        class="w-100"
        bleed="all"
        color="mint"
        :title="error.title"
        :title-line-two="error.titleLineTwo"
      />
    </template>
    <p class="m-b-20 f-smallest">
      {{error.description}}
    </p>
  </bb-offer-thank-you-page>
</template>

<script>
import { BbOfferThankYouPage, BbHero } from '@bigbank/interface-components'
import { mapState } from 'pinia'
import { translationPicker } from '../../util/translation'
import { useAppStore } from '@/store/app.store'

const ErrorCode = {
  Underaged: 'UNDERAGED'
}

export default {
  components: {
    BbOfferThankYouPage,
    BbHero
  },
  computed: {
    ...mapState(useAppStore, ['channelConfig', 'language', 'status']),
    errorCode () {
      if (this.status.isCustomerUnderaged) {
        return ErrorCode.Underaged
      }

      return null
    },
    error () {
      switch (this.errorCode) {
        case ErrorCode.Underaged:
          return {
            title: this.$pgettext('error_view', 'Hello, ${name}.').replace('${name}', this.status.customer.firstName),
            titleLineTwo: this.$pgettext('error_view_underaged', 'Thank you for your interest for opening a deposit in BigBank'),
            description: this.$pgettext('error_view_underaged', 'In order to proceed with the registration you have to be at least 18 years old If you have any further questions, you are welcome to contact us at number: ${phoneNumber}')
              .replace('${phoneNumber}', this.channelConfig.phone),
            buttonText: this.$pgettext('error_view_underaged', 'Continue')
          }
        default:
          return null
      }
    }
  },
  methods: {
    onClick () {
      document.location = translationPicker(this.channelConfig.homeUrl, this.language)
    }
  }
}
</script>
