<template>
  <component :is="currentView" v-if="flowType !== null" />
</template>

<script>
import { useAppStore } from '@/store/app.store'
import Selector from './Selector.vue'
import { mapState, mapWritableState } from 'pinia'

export default {
  name: 'common-registration',
  components: {
    Selector
  },
  watch: {
    currentStep (newValue) {
      this.activeView = this.views[newValue]
    }
  },
  computed: {
    ...mapState(useAppStore, ['currentStep', 'flowType']),
    ...mapWritableState(useAppStore, ['activeView']),
    currentView () {
      return this.views[this.currentStep]
    }
  },
  created () {
    if (this.activeView === null) {
      this.activeView = this.currentView
    }
  }
}
</script>
