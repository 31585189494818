class GoogleAnalyticsTrackerService {
    createTracker() {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
            // eslint-disable-next-line prefer-rest-params
            window.dataLayer.push(arguments);
        };
    }
    setCustomDimension(dimension, value) {
        window.gtag('set', {
            [dimension]: value
        });
    }
    async getData() {
        const [userAgent, googleAnalyticsCustomerId, googleAnalyticsSessionId, screenResolution, viewportSize] = await Promise.all([
            this.getUserAgent(),
            this.getGaClientId(),
            this.getGaSessionId(),
            this.getGaScreenResolution(),
            this.getGaViewportSize()
        ]);
        return {
            userAgent,
            googleAnalyticsCustomerId: googleAnalyticsCustomerId?.toString(),
            googleAnalyticsSessionId: googleAnalyticsSessionId?.toString(),
            screenResolution,
            viewportSize,
            gclid: this.getGoogleAdsIds('gclid'),
            gclidExpiryDate: this.getGoogleAdsIds('gclid_expiry_date'),
            wbraid: this.getGoogleAdsIds('wbraid'),
            wbraidExpiryDate: this.getGoogleAdsIds('wbraid_expiry_date')
        };
    }
    getMeasurementId() {
        return window.G_MEASUREMENT_ID ?? 'G-X';
    }
    getHasGtag() {
        return !!window.GTM;
    }
    async getGaClientId() {
        const clientId = window.document.cookie.match(/_ga=(.+?);/)?.[1].split('.').slice(-2).join('.');
        return await this.getProperty('client_id', clientId);
    }
    async getGaSessionId() {
        const sessionId = window.document.cookie.match(/_ga_[^=]+=([^;]+)/g)?.[0].split('.')[2];
        return await this.getProperty('session_id', sessionId);
    }
    async getGaScreenResolution() {
        const { width, height } = window.screen;
        const { devicePixelRatio = 1 } = window;
        return await this.getProperty('screen_resolution', `${width * devicePixelRatio}x${height * devicePixelRatio}`);
    }
    async getGaViewportSize() {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        return await this.getProperty('viewport_size', `${vw}x${vh}`);
    }
    getUserAgent() {
        return navigator.userAgent;
    }
    async getProperty(property, defaultValue) {
        if (!this.getHasGtag()) {
            return defaultValue;
        }
        return new Promise(resolve => {
            const timeout = setTimeout(() => {
                resolve(defaultValue);
            }, 1000);
            window.gtag('get', this.getMeasurementId(), property, (value) => {
                clearTimeout(timeout);
                resolve(value ?? defaultValue);
            });
        });
    }
    getGoogleAdsIds(value) {
        let res = '';
        let cookieName;
        if (value.includes('gclid')) {
            cookieName = '_gcl_aw';
        }
        else if (value.includes('wbraid')) {
            cookieName = '_gcl_gb';
        }
        const cookie = document.cookie.match(new RegExp('(^| )' + cookieName + '=([^;]+)'));
        if (cookie) {
            if (value.includes('expiry_date')) {
                const NINETY_DAYS_IN_MS = 90 * 24 * 60 * 60 * 1000;
                res = new Date(cookie[2].split('.')[1] * 1000 + NINETY_DAYS_IN_MS).toISOString();
            }
            else {
                res = cookie[2].split('.')[2];
            }
        }
        return res;
    }
}
export default new GoogleAnalyticsTrackerService();
