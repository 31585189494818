<template>
  <div class="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import errorCodeMap from './errors/errorCodes'
import api from './api'
import { CUSTOM_DIMENSIONS } from './views/common/constants'
import googleAnalyticsTrackerService from './util/googleAnalyticsTrackerService'
import { isString } from 'lodash'
import { useAppStore } from './store/app.store'
import { useAnalyticsStore } from './store/analytics.store'

export default {
  name: 'bb-welcome',
  data () {
    return {
      fetching: false,
      dataFetched: false
    }
  },
  computed: {
    ...mapState(useAppStore, ['customerType', 'language', 'supportedLanguages'])
  },
  methods: {
    ...mapActions(useAppStore, ['setError', 'getStatus', 'changeLanguage', 'setProductType']),
    ...mapActions(useAnalyticsStore, ['updateTrackingMetadata']),
    setDocumentTitle () {
      document.title = this.$pgettext('browser_tab_title', 'Bigbank')
    },
    hasQueryParams () {
      return this.$route.query && Object.values(this.$route.query).length > 0
    },
    async handleLanguageParams () {
      const { lang } = this.$route.query

      if (typeof lang === 'string') {
        const supportedLocales = this.supportedLanguages.map((lang) => lang.key)

        if (supportedLocales.includes(lang)) {
          await this.changeLanguage(lang)
        } else {
          await this.changeLanguage(supportedLocales[0])
        }
      }
    },
    handleProductType () {
      const { product, productName } = this.$route.query

      if (isString(product)) {
        this.setProductType(product.toUpperCase())
      } else if (isString(productName)) {
        this.setProductType(productName.toUpperCase())
      }
    },
    setupTracking () {
      const {
        amount,
        bbsource,
        bbchannel,
        bbcampaign,
        bbkeyword,
        bbmedium,
        product,
        productName,
        utm_source: utmSource,
        utm_term: utmTerm
      } = this.$route.query

      googleAnalyticsTrackerService.setCustomDimension(CUSTOM_DIMENSIONS.APPLICATION_ID, 'UNKNOWN')
      googleAnalyticsTrackerService.setCustomDimension(CUSTOM_DIMENSIONS.PRODUCT_NAME, product || productName)
      googleAnalyticsTrackerService.setCustomDimension(CUSTOM_DIMENSIONS.SOURCE, bbsource || utmSource)
      googleAnalyticsTrackerService.setCustomDimension(CUSTOM_DIMENSIONS.TERM, utmTerm)

      if (this.$gtm && this.$gtm.enabled()) {
        window.dataLayer.push({
          bbsource,
          bbchannel,
          bbmedium,
          bbcampaign,
          bbkeyword,
          amount,
          product
        })
      }
    },
    async handleExternalParameters () {
      return api.saveExternalParameters(this.$route.query)
    },
    async fetchData () {
      this.fetching = true
      if (this.hasQueryParams()) {
        this.handleProductType()
        await this.handleLanguageParams()
        await this.handleExternalParameters()
        this.setupTracking()
      }

      await this.getStatus({ showLoader: true })
      this.fetching = false
      this.dataFetched = true
    }
  },
  mounted () {
    this.setDocumentTitle()
    if (window.MAINTENANCE_MODE_ENABLED) {
      this.setError({
        redirect: true,
        code: errorCodeMap.MAINTENANCE_ENABLED
      })
    }

    this.fetchData()

    setInterval(() => fetch(window.LOGIN_SERVICE_URL + '/heartbeat', { credentials: 'include' }), 20000)
  },
  async created () {
    googleAnalyticsTrackerService.createTracker()
    this.updateTrackingMetadata({ ...(await googleAnalyticsTrackerService.getData()) })
  },
  watch: {
    supportedLanguages (languages) {
      const supportedLocales = languages.map((lang) => lang.key)
      if (!supportedLocales.includes(this.language)) {
        this.changeLanguage(supportedLocales[0])
      }
    }
  }
}
</script>

<style scoped lang="scss">
.app {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 100vh;
}
</style>
