<script>
import { isFunction, isObject, isArray, isNull } from 'lodash'
import { mapActions, mapState } from 'pinia'
import api from '../../api'
import { FormValidation, isDeepTINValidationRequired } from '@bigbank/dc-common/validators/validation.config'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import Vue from 'vue'
import { CountryChannel } from '@bigbank/dc-common/config'
import { useAppStore } from '@/store/app.store'
import { useAnalyticsStore } from '@/store/analytics.store'

export default {
  data () {
    return {
      shouldRetryFillInitialValues: true,
      error: undefined,
      form: {},
      hCaptchaInstance: undefined
    }
  },
  components: {
    VueHcaptcha
  },
  props: {
    initialValues: {
      type: Array
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.error = undefined
        this.$validator.reset({ scope: 'server' })
      }
    },
    commonInitialValues () {
      if (
        this.channel === CountryChannel.LV &&
        this.isV2Flow &&
        this.shouldRetryFillInitialValues
      ) {
        this.fillInitialValues()
        this.shouldRetryFillInitialValues = false
      }
    },
    language () {
      this.$nextTick(() => {
        this.errors.items.forEach(e => {
          if (e.regenerate) {
            e.msg = e.regenerate()
          }
        })
      })
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setRegistrationId']),
    ...mapActions(useAnalyticsStore, ['sendAnalyticsEvent']),
    prepareFormData (formData) {
      if (this.trackingMetadata.googleAnalyticsCustomerId) {
        formData.metadata = {
          tracking: {
            ...this.trackingMetadata
          }
        }
      }
      formData.customerType = this.customerType
      formData.product = this.productType

      return formData
    },
    async registerFormData (formData) {
      formData = this.prepareFormData(formData)

      if (!isNull(this.backgroundInfoEventName)) {
        this.sendAnalyticsEvent({
          pageUrlPath: '/',
          eventName: this.backgroundInfoEventName
        }).catch(err => console.log(err))

        if (this.$gtm && this.$gtm.enabled()) {
          this.$gtm.trackEvent({
            event: this.backgroundInfoEventName,
            action: 'click',
            label: 'Onboarding'
          })
        }
      }

      const { registrationId } = await api.register(formData)

      this.setRegistrationId(registrationId)
    },
    async partialRegistration (formData, hCaptchaToken) {
      formData = this.prepareFormData({ ...formData })
      await api.registerStep(formData, hCaptchaToken)
    },
    handleError (err) {
      this.error = err
      window.scrollTo(0, 0)
    },
    cleanInput (value) {
      return value.replace(' ', '').trim()
    },
    fieldConfig (fieldName) {
      let config = {}

      if (Array.isArray(this.fieldDefinitions)) {
        const foundConfigs = this.fieldDefinitions.filter(field => field.name === fieldName)

        if (foundConfigs && foundConfigs.length > 0) {
          config = foundConfigs[0]
        }
      }

      this.allInitialValues.filter(field => field && field.name === fieldName)
        .forEach(field => {
          config = Object.assign({}, config, field)
        })

      return config
    },
    showField (fieldName) {
      const config = this.fieldConfig(fieldName)

      return isFunction(config.showField)
        ? config.showField(this.form)
        : (!!config.name && !config.hidden)
    },
    isDisabled (fieldName) {
      const config = this.fieldConfig(fieldName)

      if (config?.ifPrefilledThenDisabled) {
        return !isNull(this.initialValue(fieldName))
      }

      return config?.isDisabled === true
    },
    initialValue (fieldName, defaultValue = null) {
      const config = this.fieldConfig(fieldName)

      return config && config.value !== undefined ? config.value : defaultValue
    },
    orderStyle (fieldName) {
      let fieldIndex = Array.isArray(this.fieldDefinitions) ? this.fieldDefinitions.map(field => field.name).indexOf(fieldName) : -1
      fieldIndex = (fieldIndex >= 0) ? fieldIndex + 1 : 100

      return 'order: ' + fieldIndex + ';'
    },
    getValidationRules (fieldName) {
      if (this.showField(fieldName)) {
        const validationRules = { required: true }
        const fieldConfig = this.fieldConfig(fieldName)

        if (fieldConfig.validator) {
          validationRules[fieldConfig.name] = fieldConfig.name
        } else if (fieldConfig.name === 'email') {
          validationRules['email-extended'] = { allow_utf8_local_part: false }
        } else if (fieldConfig.name === 'dateOfBirth') {
          validationRules.date_format = 'date_format'
        } else if (fieldConfig.name === 'taxResidency') {
          if (!isDeepTINValidationRequired(FormValidation.OnBoardingFlow, this.channel, this.form.taxResidency)) {
            validationRules.required = null
          }
        } else if (fieldConfig.name === 'businessActivityCode') {
          validationRules.business_activity_code = 'business_activity_code'
        } else if (fieldConfig.name === 'dualCitizenship') {
          validationRules.dual_citizenship = 'dual_citizenship'
        }

        if (this.isLatinLettersValidationRequired(fieldName)) {
          validationRules[`${fieldName}_latin_letters_only`] = `${fieldName}_latin_letters_only`
        }

        return validationRules
      }

      return ''
    },
    addValidatorRules () {
      if (Array.isArray(this.fieldDefinitions)) {
        this.fieldDefinitions.forEach(field => {
          if (field.validator) {
            const validator = {
              validate: field.validator.validate
            }

            if (field.validator.message) {
              validator.getMessage = () => field.validator.message
            }

            this.$validator.extend(field.name, validator)
          }

          if (this.isLatinLettersValidationRequired(field.name)) {
            this.$validator.extend(`${field.name}_latin_letters_only`, this.latinLettersValidator)
          }
        })
      }
    },
    async onSubmit () {
      if (this.featureFlags?.enableHCaptcha) {
        this.hCaptchaInstance.execute()
      } else {
        await this.submitAfterHCaptchaVerify(null)
      }
    },
    injectHCaptcha () {
      if (this.$refs?.form && this.featureFlags?.enableHCaptcha) {
        const HCaptchaCtor = Vue.extend(VueHcaptcha)

        this.hCaptchaInstance = new HCaptchaCtor({
          propsData: {
            size: 'invisible',
            sitekey: window.HCAPTCHA_SITE_KEY,
            language: window.LANG
          }
        })

        this.hCaptchaInstance.$mount()
        this.hCaptchaInstance.$on('verify', async hCaptchaToken => await this.submitAfterHCaptchaVerify(hCaptchaToken))

        this.$refs.form.appendChild(this.hCaptchaInstance.$el)
      }
    },
    isLatinLettersValidationRequired (fieldName) {
      return isObject(this.latinLettersValidationConfig) &&
            isArray(this.latinLettersValidationConfig.fields) &&
            this.latinLettersValidationConfig.fields.includes(fieldName)
    },
    fillInitialValues () {
      this.allInitialValues.forEach(field => {
        if (field && field.name && Object.prototype.hasOwnProperty.call(this.form, field.name)) {
          this.form[field.name] = field.value
        }
      })
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'currentStep',
      'customerType',
      'productType',
      'language',
      'status',
      'featureFlags',
      'isV2Flow',
      'backgroundInfoEventName'
    ]),
    ...mapState(useAnalyticsStore, ['trackingMetadata']),
    errorMessage () {
      if (!this.error) {
        return undefined
      }

      const msg = this.translations.errors[this.error.type] || this.translations.errors.INTERNAL_ERROR
      if (this.error.type === 'VALIDATION_FAILED' && this.error.data.errors) {
        this.error.data.errors.forEach(error => {
          const field = this.$validator.fields.find({ name: error.field, scope: this.$options.scope })
          if (field) {
            this.$validator.errors.add({
              id: field.id,
              field: error.field,
              msg: '',
              scope: 'server'
            })
            field.setFlags({
              invalid: true,
              valid: false,
              validated: true
            })
          }
        })
      }

      if (msg) {
        return msg.replace('#CODE#', this.error.correlationId)
      }

      return ''
    },
    translations () {
      return {
        errors: {
          INTERNAL_ERROR: this.$pgettext('registration_error', 'Something went wrong, please try again later. If the error persists, please contact as an provide the following error code: #CODE#.'),
          VALIDATION_FAILED: this.$pgettext('registration_error', 'Form validation failed, please double check your data and try again.')
        }
      }
    },
    latinLettersValidator () {
      return {
        validate: (value) => !!value.match(/^[āčēģīķļņšūža-z\-.\s]+$/i), // (āčēģīķļņšūža-z)(-)(.)( )
        getMessage: () => this.$pgettext('registration', 'Application can only be filled with Latin letters')
      }
    },
    latinLettersValidationConfig () {
      return {
        [CountryChannel.LV]: {
          fields: [
            'firstName',
            'surname',
            'customFieldOfActivity',
            'isPepReason'
          ]
        }
      }[this.channel] ?? null
    },
    commonInitialValues () {
      return []
    },
    allInitialValues () {
      let combinedValues = []

      if (Array.isArray(this.commonInitialValues) && this.commonInitialValues.length > 0) {
        combinedValues = combinedValues.concat(this.commonInitialValues)
      }
      if (Array.isArray(this.initialValues) && this.initialValues.length > 0) {
        combinedValues = combinedValues.concat(this.initialValues)
      }

      return combinedValues
    }
  },
  mounted () {
    this.fillInitialValues()
    this.injectHCaptcha()
  }
}
</script>
