import { defineStore } from 'pinia';
import { additionalDetailsTemplate, personalDetailsTemplate, addressTemplate } from './store.util';
import api from '../api';
export const useLithuaniaStore = defineStore('lithuania', {
    state: () => ({
        legalForm: {
            personalDetails: {
                personalIdentityCode: undefined,
                companyName: undefined,
                companyRegistrationCode: undefined,
                businessActivityCode: undefined,
                bankAccountNumber: undefined,
                firstName: undefined,
                surname: undefined,
                phoneNumber: undefined,
                phoneNumberPrefix: undefined,
                marketingConsent: false,
                email: undefined
            },
            additionalDetails: {
                fieldOfActivity: undefined,
                customFieldOfActivity: undefined,
                dateOfBirth: undefined,
                placeOfBirth: undefined,
                taxIdNumber: undefined,
                taxResidency: undefined,
                citizenship: undefined,
                dualCitizenship: undefined,
                isPep: undefined
            },
            addressDetails: {
                contactAddress: addressTemplate(),
                registrationAddress: addressTemplate()
            }
        },
        privateForm: {
            personalDetails: personalDetailsTemplate({
                externalRegistryCheckConsent: null,
                phonePassword: undefined
            }),
            additionalDetails: additionalDetailsTemplate(),
            addressDetails: {
                contactAddress: addressTemplate()
            }
        }
    }),
    actions: {
        async getPostcode(opts = { addressType: 'contactAddress', customerType: 'private' }) {
            const form = opts.customerType === 'legal' ? this.legalForm.addressDetails : this.privateForm.addressDetails;
            const address = {
                city: form[opts.addressType].city,
                street: form[opts.addressType].streetAddress,
                houseNumber: form[opts.addressType].houseNumber
            };
            const res = await api.getPostcode(address);
            if (res.postalCode) {
                form.addressDetails[opts.addressType].postcode = res.postalCode;
            }
        }
    }
});
