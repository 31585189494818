import Layout from './Layout.vue';
import Registration from './Registration.vue';
import LegalCustomerRegistration from './legal/Registration.vue';
import ThankYou from './ThankYou.vue';
import Failure from './../common/Failure.vue';
import ConfirmRegistrationData from '@/views/lv/ConfirmRegistrationData.vue';
import RegistrationSigned from '@/views/lv/RegistrationSigned.vue';
const routes = [
    {
        path: '/',
        component: Registration,
        name: 'register'
    },
    {
        path: '/confirm-registration-data',
        component: ConfirmRegistrationData,
        name: 'confirm-registration-data'
    },
    {
        path: '/registration-signed',
        component: RegistrationSigned,
        name: 'registration-signed'
    },
    {
        path: '/legal',
        component: LegalCustomerRegistration,
        name: 'legal-customer-registration'
    },
    {
        path: '/thank-you',
        component: ThankYou,
        name: 'thank-you'
    },
    {
        path: '/failure',
        component: Failure,
        name: 'failure',
        meta: {
            hideProgressbar: true
        }
    }
];
export default (router) => {
    router.addRoute({
        path: '/',
        component: Layout,
        children: routes
    });
};
