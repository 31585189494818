import { defineStore } from 'pinia';
import { additionalDetailsTemplate, personalDetailsTemplate, addressTemplate } from './store.util';
export const useEstoniaStore = defineStore('estonia', {
    state: () => ({
        legalForm: {
            personalDetails: {
                personalIdentityCode: undefined,
                companyName: undefined,
                companyRegistrationCode: undefined,
                bankAccountNumber: undefined,
                firstName: undefined,
                surname: undefined,
                phoneNumber: undefined,
                phoneNumberPrefix: undefined,
                marketingConsent: false,
                email: undefined
            },
            additionalDetails: additionalDetailsTemplate(),
            addressDetails: {
                registrationAddress: addressTemplate(),
                contactAddress: addressTemplate()
            }
        },
        privateForm: {
            personalDetails: personalDetailsTemplate(),
            additionalDetails: additionalDetailsTemplate(),
            addressDetails: {
                registrationAddress: addressTemplate(),
                contactAddress: addressTemplate()
            }
        },
        currentAccountForm: {
            personalDetails: {
                firstName: undefined,
                surname: undefined,
                personalIdentityCode: undefined,
                email: undefined,
                preferredContactLanguage: undefined,
                phoneNumber: undefined,
                phoneNumberPrefix: undefined,
                marketingConsent: false
            },
            additionalDetails: {
                taxIdNumber: undefined,
                taxResidency: undefined,
                totalIncome: undefined,
                incomeTypes: [],
                specifiedIncomeTypes: undefined,
                purposeOfCustomerRelationship: [],
                plannedMonthlyTurnover: undefined,
                isPep: undefined
            },
            addressAndBackground: {
                contactAddress: addressTemplate(),
                education: undefined,
                activity: undefined,
                fieldOfActivity: undefined,
                employer: undefined
            }
        }
    })
});
