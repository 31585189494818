import { defineStore } from 'pinia';
import { additionalDetailsTemplate, personalDetailsTemplate } from './store.util';
export const useSwedenStore = defineStore('sweden', {
    state: () => ({
        privateForm: {
            personalDetails: personalDetailsTemplate(),
            additionalDetails: additionalDetailsTemplate(),
            depositDetails: {
                clearingNumber: undefined,
                bankAccountNumber: undefined,
                moneyOrigins: [],
                otherMoneyOrigin: undefined,
                moneyDetailsConsent: false,
                depositConsent: false
            }
        }
    })
});
