export function additionalDetailsTemplate() {
    return {
        fieldOfActivity: undefined,
        customFieldOfActivity: undefined,
        dateOfBirth: undefined,
        placeOfBirth: undefined,
        taxIdNumber: undefined,
        taxResidency: undefined,
        citizenship: undefined,
        dualCitizenship: undefined,
        isPep: undefined,
        isPepReason: undefined
    };
}
export function personalDetailsTemplate(extra = {}) {
    return {
        firstName: undefined,
        surname: undefined,
        personalIdentityCode: undefined,
        email: undefined,
        phoneNumber: undefined,
        phoneNumberPrefix: undefined,
        marketingConsent: false,
        ...extra
    };
}
export function addressTemplate() {
    return {
        streetAddress: undefined,
        city: undefined,
        postalCode: undefined,
        postcode: undefined,
        country: undefined,
        county: undefined,
        houseNumber: undefined,
        flatNumber: undefined
    };
}
