import { defineStore } from 'pinia';
import { additionalDetailsTemplate, personalDetailsTemplate } from './store.util';
export const useFinlandStore = defineStore('finland', {
    state: () => ({
        privateForm: {
            personalDetails: personalDetailsTemplate(),
            additionalDetails: additionalDetailsTemplate()
        }
    })
});
