<script>
import { mapState, mapActions } from 'pinia'
import { REGISTRATION_STATUSES } from '../../const'
import { REGISTRATION_SIGNING_STATUS } from '@/const'
import { useAppStore } from '@/store/app.store'

export default {
  data () {
    return {
      statusPollingReference: null,
      routes: {
        0: { name: 'register' },
        1: { name: 'thank-you' },
        2: { name: 'confirm-registration-data' },
        3: { name: 'registration-signed' }
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['status', 'constants', 'isV3Flow']),
    step () {
      const { status, process } = this.status || {}
      const { COMPLETED, PENDING } = this.constants.REGISTRATION_STATUSES
      const isFormCompleted = [true, undefined].includes(process?.isFormCompleted)

      if (process?.signingStatus === REGISTRATION_SIGNING_STATUS.IN_PROGRESS) {
        return 2
      } else if (process?.signingStatus === REGISTRATION_SIGNING_STATUS.COMPLETED) {
        return 3
      } else if (COMPLETED === status || (PENDING === status && isFormCompleted)) {
        return 1
      }

      return 0
    }
  },
  watch: {
    step (step) {
      if (this.$route.name !== this.routes[step].name) {
        this.$router.push(this.routes[step])
      }
    },
    'status.autoLoginUrl' (url) {
      if (url) {
        this.setLoader({ isLoading: true })
        clearTimeout(this.statusPollingReference)
        window.location = url
      }
    },
    'status.status' (status) {
      if (status === REGISTRATION_STATUSES.FAILED && this.isV3Flow) {
        this.setError({ code: '404-current-account', redirect: true })
      } else if (status === REGISTRATION_STATUSES.FAILED) {
        this.$router.push('failure')
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['getStatus', 'setLoader', 'getIdentificationData', 'setError', 'setIsLoadingOverlayShown']),
    pollStatus () {
      this.statusPollingReference = setTimeout(() => {
        this.getStatus({ showLoader: false })
          .then((status) => {
            if (status?.hasCrmInsertionError) {
              this.setError({
                redirect: true
              })
              return
            }

            this.setIsLoadingOverlayShown(!!status?.hasTooManyEvrotrustRequestsError)
            this.getIdentificationData({ showLoader: false })
          })
          .catch(() => {
            // Ignore errors
          })
          .then(() => this.pollStatus())
      }, 15000)
    }
  },
  mounted () {
    this.pollStatus()
  },
  beforeDestroy () {
    clearTimeout(this.statusPollingReference)
  }
}
</script>
