import { useAppStore } from '@/store/app.store';
import RequestError from './RequestError';
import errorCodeMap from './errorCodes';
function handleError(err) {
    const appSore = useAppStore();
    switch (err.constructor) {
        case RequestError:
            return appSore.setError({
                redirect: true,
                correlationId: err.correlationId,
                code: errorCodeMap[err.type] || errorCodeMap.INTERNAL_ERROR
            });
    }
}
window.addEventListener('unhandledrejection', function (event) {
    handleError(event.reason);
});
window.onerror = function (msg, url, line, col, err) {
    handleError(err);
};
export default handleError;
