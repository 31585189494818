<template>
  <component :is="currentView"/>
</template>

<script>
import PersonalDetails from './PersonalDetails.vue'
import AdditionalDetails from './AdditionalDetails.vue'
import AddressDetails from './AddressDetails.vue'
import CommonRegistration from '../common/CommonRegistration.vue'
import { mapState } from 'pinia'
import { useAppStore } from '@/store/app.store'
import stepsConfig from '@/views/lv/steps.config'

export default {
  components: {
    PersonalDetails,
    AdditionalDetails,
    AddressDetails
  },
  extends: CommonRegistration,
  computed: {
    ...mapState(useAppStore, ['isV2Flow']),
    views () {
      if (this.isV2Flow) {
        return [
          'selector',
          ...stepsConfig.private
        ]
      }

      return stepsConfig.private
    }
  }
}
</script>
