<template>
  <personal-details
      :field-definitions="fieldDefinitions"
      :phone-number-prefix="phoneNumberPrefix"
      :channel="channel"
      :date-format="dateFormat"
      :locale="language"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
      :personal-identity-code-mask="personalIdentityCodeMask"
  ></personal-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import PersonalDetails from '@/views/common/PersonalDetails.vue'
import * as idNumberValidator from '@bigbank/dc-common/validators/lv/idNumber'
import * as phoneNumberValidator from '@bigbank/dc-common/validators/lv/phoneNumber'
import * as phonePasswordValidator from '@bigbank/dc-common/validators/lv/phonePassword'
import { LVValidator } from '@bigbank/dc-common/validators/lv/LVValidator.class'
import { cleanInput } from '@/views/common/util'
import dayjs from 'dayjs'
import { useAppStore } from '@/store/app.store'
const validator = new LVValidator()

export default {
  name: 'personal-details-lv',
  components: {
    PersonalDetails
  },
  data () {
    return {
      isLoading: true,
      phoneNumberPrefix: '+371',
      personalIdentityCodeMask: '111111-11111',
      dateFormat: 'DD.MM.YYYY',
      hasAcademicTitle: false,
      marketingConsentVisible: false,
      fieldDefinitions: [
        { name: 'firstName' },
        { name: 'surname' },
        {
          name: 'personalIdentityCode',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid ID number'),
            validate: value => idNumberValidator(value)
          }
        },
        {
          name: 'dateOfBirth',
          showField: form => {
            if (!this.isV2Flow) {
              return false
            }

            const idNumber = this.status?.customer?.personalIdentityCode ?? ''
            const show = validator.isIdNumberInNewFormat(idNumber)

            if (!show && !form.dateOfBirth) {
              form.dateOfBirth = dayjs(
                validator.getBirthdayDateStringYYYYMMDD(idNumber),
                'YYYY-MM-DD',
                true
              ).format(this.dateFormat)
            }

            return show
          }
        },
        { name: 'email' },
        {
          name: 'phoneNumber',
          validator: {
            message: this.$pgettext('registration_lv', 'Please insert valid phone number'),
            validate: value => {
              try {
                return phoneNumberValidator(cleanInput(value), this.channel)
              } catch (err) {
                return false
              }
            }
          }
        },
        {
          name: 'phonePassword',
          showField: () => !this.isV2Flow,
          validator: {
            message: this.$pgettext('registration', 'Password must consist of 3-10 letters or numbers'),
            validate: value => phonePasswordValidator(value)
          }
        },
        {
          name: 'externalRegistryCheckConsent',
          showField: () => !this.isV2Flow
        },
        {
          name: 'marketingConsent'
        },
        {
          name: 'dataProcessingSilentConsent',
          showField: () => !this.isV2Flow
        }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['status', 'channel', 'language', 'isV2Flow'])
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep'])
  }
}
</script>
