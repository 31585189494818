import Layout from './Layout.vue';
import Registration from './Registration.vue';
import ThankYou from './ThankYou.vue';
import Failure from './../common/Failure.vue';
const routes = [
    {
        path: '/',
        component: Registration,
        name: 'register'
    },
    {
        path: '/thank-you',
        component: ThankYou,
        name: 'thank-you'
    },
    {
        path: '/failure',
        component: Failure,
        name: 'failure',
        meta: {
            hideProgressbar: true
        }
    }
];
export default (router) => {
    router.addRoute({
        path: '/',
        component: Layout,
        children: routes
    });
};
