import cloneDeep from 'lodash/cloneDeep';
function getCountriesList(locale, countriesList, excludeByCode = []) {
    let countries = cloneDeep(countriesList)
        .map(country => ({
        value: country.countryCode,
        text: country.translation
    }))
        .sort((a, b) => a.text.localeCompare(b.text, locale));
    if (Array.isArray(excludeByCode)) {
        countries = countries.filter((country) => !excludeByCode.includes(country.value));
    }
    return countries;
}
function prependLocalCountryOption(countryList, channel) {
    const localCountry = countryList.find(country => country.value === channel);
    return localCountry !== undefined ? [localCountry, ...countryList] : countryList;
}
export { getCountriesList, prependLocalCountryOption };
