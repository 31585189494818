<template>
  <personal-details
      :field-definitions="fieldDefinitions"
      :phone-number-prefix="phoneNumberPrefix"
      :channel="channel"
      :locale="language"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
  ></personal-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import PersonalDetails from '@/views/common/PersonalDetails.vue'
import * as idNumberValidator from '@bigbank/dc-common/validators/se/idNumber'
import * as phoneNumberValidator from '@bigbank/dc-common/validators/se/phoneNumber'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'personal-details-se',
  components: {
    PersonalDetails
  },
  data () {
    return {
      isLoading: true,
      phoneNumberPrefix: '+46',
      form: {
        personalIdentityCode: undefined,
        firstName: undefined,
        surname: undefined,
        phoneNumber: undefined,
        phoneNumberPrefix: undefined,
        marketingConsent: false,
        email: undefined
      },
      hasAcademicTitle: false,
      marketingConsentVisible: true,
      fieldDefinitions: [
        { name: 'firstName' },
        { name: 'surname' },
        {
          name: 'personalIdentityCode',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid ID number'),
            validate: value => idNumberValidator.validateShort(value)
          }
        },
        { name: 'email' },
        {
          name: 'phoneNumber',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid phone number'),
            validate: value => {
              try {
                let cleanedValue = value.trim()

                if (cleanedValue.startsWith(this.phoneNumberPrefix)) {
                  const phoneNumber = cleanedValue.slice(this.phoneNumberPrefix.length)
                  cleanedValue = this.phoneNumberPrefix + phoneNumber.trim()
                }

                return phoneNumberValidator(cleanedValue, this.channel)
              } catch (err) {
                return false
              }
            }
          }
        },
        { name: 'marketingConsent' },
        { name: 'dataProcessingSilentConsent' },
        { name: 'selfserviceTermsConfirmation' }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language'])
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep'])
  }
}
</script>
