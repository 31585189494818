<template>
  <component :is="currentView"/>
</template>

<script>
import PersonalDetails from './PersonalDetails.vue'
import AdditionalDetails from './AdditionalDetails.vue'
import RegistrationAddressDetails from './RegistrationAddressDetails.vue'
import CommonRegistration from '../common/CommonRegistration.vue'
import stepsConfig from '@/views/cb/steps.config'

export default {
  computed: {
    views () {
      return stepsConfig.private
    }
  },
  components: {
    PersonalDetails,
    AdditionalDetails,
    RegistrationAddressDetails
  },
  extends: CommonRegistration
}
</script>
