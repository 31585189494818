import { defineStore } from 'pinia';
import Vue from 'vue';
import { Validator } from 'vee-validate';
import { LoanType, DepositType } from '@bigbank/dc-common/enums/productTypes';
import api from '../api';
import errorHandler from '../errors/errorHandler';
import { CUSTOMER_TYPE, PRODUCT_TYPE, BG_IDENTIFICATION_STATUSES, REGISTRATION_STATUSES, FLOW_TYPE } from '@/const';
import { CountryChannel, Language } from '@bigbank/dc-common/config';
import { isNil, isNull } from 'lodash';
import { useAnalyticsStore } from './analytics.store';
import { useEstoniaStore } from './estonian.store';
import { useSwedenStore } from './sweden.store';
import { useLatviaStore } from './latvia.store';
import { useFinlandStore } from './finland.store';
import { useCrossBorderStore } from './crossBorder.store';
import { useLithuaniaStore } from './lithuania.store';
import { backgroundInfoEventsConfig } from '@/tracking/tracking.config';
const channel = window.CHANNEL;
const language = window.LANG || Language.en;
const languages = window.LANGS || { private: [language], corporate: [language] };
let statusRequestPromise = null;
export const getModuleByChannel = (channel) => {
    switch (channel) {
        case CountryChannel.DE:
        case CountryChannel.AT:
        case CountryChannel.NL:
            return useCrossBorderStore();
        case CountryChannel.EE:
            return useEstoniaStore();
        case CountryChannel.LV:
            return useLatviaStore();
        case CountryChannel.FI:
            return useFinlandStore();
        case CountryChannel.SE:
            return useSwedenStore();
        case CountryChannel.LT:
            return useLithuaniaStore();
    }
    return null;
};
export const useAppStore = defineStore('app', {
    state: () => ({
        isLoading: false,
        isLoadingOverlayShownValue: false,
        isCustomerIdentificationRequired: null,
        registrationId: null,
        isRegistrationTrackingEventSent: false,
        isCustomer: null,
        customerType: CUSTOMER_TYPE.Private,
        productType: null,
        loadingText: '',
        featureFlags: window.FEATURES || {},
        channel,
        language,
        languages,
        countriesData: undefined,
        channelConfig: window.CHANNEL_CONFIG,
        hasSsoSession: null,
        status: {
            customer: undefined,
            sessionId: undefined,
            status: undefined,
            process: undefined,
            isAuthenticated: false,
            loginUrl: undefined
        },
        customerCitizenCountryCode: undefined,
        constants: {
            IDENTIFICATION_STATUSES: BG_IDENTIFICATION_STATUSES,
            REGISTRATION_STATUSES,
            PRODUCT_TYPE,
            BG: 'BG'
        },
        error: {
            code: null,
            correlationId: null
        },
        currentStep: 0,
        activeView: null,
        flowType: null
    }),
    getters: {
        supportedLanguages(state) {
            const type = state.customerType === CUSTOMER_TYPE.Private ? 'private' : 'corporate';
            const languages = state.languages[type] ?? state.languages.private;
            return languages.map(lang => {
                return {
                    label: lang.toUpperCase(),
                    key: lang,
                    active: state.language === lang
                };
            });
        },
        isLoadingOverlayShown(state) {
            if (state.channel === CountryChannel.BG) {
                return state.isLoadingOverlayShownValue;
            }
            return false;
        },
        locale(state) {
            return state.language;
        },
        currency(state) {
            switch (state.channel) {
                case CountryChannel.SE:
                    return 'SEK';
                case CountryChannel.BG:
                    return 'BGN';
                default:
                    return 'EUR';
            }
        },
        countries(state) {
            return state.countriesData || [];
        },
        registrationFlowType(state) {
            if (state.customerType === CUSTOMER_TYPE.Private && state.flowType === FLOW_TYPE.V3) {
                return 'currentAccount';
            }
            else if (state.customerType === CUSTOMER_TYPE.Private) {
                return 'private';
            }
            else {
                return 'legal';
            }
        },
        formData(state) {
            const module = getModuleByChannel(state.channel);
            if (!module) {
                return {};
            }
            let form;
            if (this.registrationFlowType === 'currentAccount') {
                form = module.currentAccountForm;
            }
            else if (this.registrationFlowType === 'private') {
                form = module.privateForm;
            }
            else {
                form = module.legalForm;
            }
            const flattenedForm = Object.values(form).reduce((acc, current) => {
                acc = { ...acc, ...current };
                return acc;
            }, {});
            return flattenedForm;
        },
        backgroundInfoEventName(state) {
            return backgroundInfoEventsConfig[state.channel]?.[this.registrationFlowType]?.[state.activeView] ?? null;
        },
        isV2Flow(state) {
            return state.flowType === FLOW_TYPE.V2;
        },
        isV3Flow(state) {
            return state.flowType === FLOW_TYPE.V3;
        }
    },
    actions: {
        setLoader({ isLoading, loadingText = '' }) {
            this.isLoading = !!isLoading;
            this.loadingText = loadingText;
        },
        setIsLoadingOverlayShown(isLoadingOverlayShown) {
            this.isLoadingOverlayShownValue = isLoadingOverlayShown;
        },
        setError(args) {
            const { redirect, ...errorParams } = args;
            if (redirect) {
                // @ts-expect-error Will be fixed with vue 3
                Vue.$router
                    ?.push({ name: 'error' })
                    .catch(() => ({}));
            }
            this.error = errorParams;
        },
        setCustomerType(type) {
            if (type && Object.values(CUSTOMER_TYPE).includes(type)) {
                this.customerType = type;
            }
        },
        setProductType(type) {
            if (type) {
                if (Object.values(LoanType).includes(type)) {
                    this.productType = PRODUCT_TYPE.Loan;
                }
                else if (Object.values(DepositType).includes(type)) {
                    this.productType = PRODUCT_TYPE.Deposit;
                }
                else if (type.toLowerCase() === PRODUCT_TYPE.CurrentAccount) {
                    this.productType = PRODUCT_TYPE.CurrentAccount;
                }
            }
        },
        updateFormData(formData) {
            const module = getModuleByChannel(this.channel);
            if (!module)
                return;
            if (this.customerType === CUSTOMER_TYPE.Private && this.flowType === FLOW_TYPE.V3) {
                module.currentAccountForm = { ...module.currentAccountForm, ...formData };
            }
            else if (this.customerType === CUSTOMER_TYPE.Private) {
                module.privateForm = { ...module.privateForm, ...formData };
            }
            else if (this.customerType === CUSTOMER_TYPE.Legal) {
                module.legalForm = { ...module.legalForm, ...formData };
            }
        },
        setRegistrationId(registrationId) {
            this.registrationId = registrationId;
        },
        setIsRegistrationTrackingEventSent(isRegistrationTrackingEventSent) {
            this.isRegistrationTrackingEventSent = isRegistrationTrackingEventSent;
        },
        async changeLanguage(language) {
            try {
                this.setLoader({ isLoading: true });
                await api.setLanguage(language);
            }
            catch (err) {
                errorHandler(err);
            }
            finally {
                this.language = language;
                Vue.config.language = language;
                Validator.localize(language);
                this.setLoader({ isLoading: false });
                this.getCountries(false);
            }
        },
        async getCountries(useCache = true) {
            let countries = this.countriesData;
            if (!countries || !useCache) {
                try {
                    countries = await api.getCountries();
                }
                catch (err) {
                    errorHandler(err);
                }
                this.countriesData = countries;
            }
            return countries;
        },
        async getStatus(opt = { showLoader: false }) {
            try {
                if (opt.showLoader) {
                    this.setLoader({ isLoading: true });
                }
                const hasSsoSession = await this.getSsoStatus();
                const analyticsStore = useAnalyticsStore();
                if (!statusRequestPromise) {
                    statusRequestPromise = api.status({
                        hasSsoSession,
                        product: this.productType,
                        ...analyticsStore.tracking
                    });
                }
                const status = await statusRequestPromise;
                const hasSessionExpired = !isNil(this.status.sessionId) && this.status.sessionId !== status.sessionId;
                if (hasSessionExpired) {
                    window.location.reload();
                    return;
                }
                this.status = status;
                if (typeof status.process === 'object' && typeof status.process.customerNeedsIdentification === 'boolean') {
                    this.isCustomerIdentificationRequired = status.process.customerNeedsIdentification;
                }
                this.setCustomerType(status.customerType);
                this.flowType = status.flowType ?? FLOW_TYPE.V1;
                if (status.isAuthenticated && status.flowType === FLOW_TYPE.V2 && this.currentStep === 0) {
                    this.currentStep = 1;
                }
                return status;
            }
            catch (err) {
                errorHandler(err);
            }
            finally {
                statusRequestPromise = null;
                if (opt.showLoader) {
                    this.setLoader({ isLoading: false });
                }
            }
        },
        async getIdentificationData(opt = { showLoader: false }) {
            try {
                if (opt.showLoader) {
                    this.setLoader({ isLoading: true });
                }
                const identificationData = await api.getIdentificationData();
                this.customerCitizenCountryCode = identificationData.citizenCountryCode;
                return identificationData;
            }
            catch (err) {
                errorHandler(err);
            }
            finally {
                if (opt.showLoader) {
                    this.setLoader({ isLoading: false });
                }
            }
        },
        async refresh() {
            const { status } = await api.refresh();
            if (status === 'RUNNING') {
                await this.getStatus();
            }
            return status;
        },
        async goToNextStep() {
            if (!isNull(this.backgroundInfoEventName)) {
                const analyticsStore = useAnalyticsStore();
                await analyticsStore.sendAnalyticsEvent({
                    pageUrlPath: '/',
                    eventName: this.backgroundInfoEventName
                });
                if (window.Vue.gtm && window.Vue.gtm.enabled()) {
                    window.Vue.gtm.trackEvent({
                        event: this.backgroundInfoEventName,
                        action: 'click',
                        label: 'Onboarding'
                    });
                }
            }
            this.currentStep += 1;
        },
        async getSsoStatus() {
            let hasSsoSession = this.hasSsoSession;
            if (hasSsoSession === null) {
                try {
                    const { status, data } = await api.getSsoStatus();
                    hasSsoSession = status === 200;
                    this.hasSsoSession = hasSsoSession;
                    this.isCustomer = data?.isCustomer ?? null;
                }
                catch (err) {
                    this.isCustomer = null;
                    this.hasSsoSession = null;
                }
            }
            return hasSsoSession;
        }
    }
});
