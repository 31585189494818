<template>
  <common-layout v-bind="props">
    <router-view />
  </common-layout>
</template>

<script>
import { mapState } from 'pinia'
import RoutingMixin from '../common/RoutingMixin.vue'
import CommonLayout from './../../components/CommonLayout.vue'
import { useAppStore } from '@/store/app.store'

export default {
  mixins: [
    RoutingMixin
  ],
  components: {
    CommonLayout
  },
  computed: {
    ...mapState(useAppStore, ['currentStep', 'status', 'isV2Flow']),
    props () {
      const props = {}
      const progressBar = {
        max: 3,
        value: this.currentStep + 1,
        color: 'blue'
      }

      if (this.isV2Flow) {
        props.hideLoginButton = true
        if (this.status.status) {
          props.progressBar = progressBar
        }
      } else {
        props.hideLoginButton = false
        props.progressBar = progressBar
      }

      return props
    }
  }
}
</script>
