import eeStepsConfig from '@/views/ee/steps.config';
import fiStepsConfig from '@/views/fi/steps.config';
import bgStepsConfig from '@/views/bg/steps.config';
import cbStepsConfig from '@/views/cb/steps.config';
import ltStepsConfig from '@/views/lt/steps.config';
import lvStepsConfig from '@/views/lv/steps.config';
import seStepsConfig from '@/views/se/steps.config';
import { CountryChannel } from '@bigbank/dc-common/config';
function mapStepsConfigToBackgroundInfoEvents(stepsConfig) {
    const config = {};
    Object.entries(stepsConfig).forEach(([flowType, steps]) => {
        // flowType: private | legal | currentAccount
        config[flowType] = steps.reduce((stepToEventMap, step, index) => {
            stepToEventMap = {
                ...stepToEventMap,
                [step]: `submitted-background-info-${index + 1}`
            };
            return stepToEventMap;
        }, {});
    });
    return config;
}
export const backgroundInfoEventsConfig = {
    [CountryChannel.BG]: mapStepsConfigToBackgroundInfoEvents(bgStepsConfig),
    [CountryChannel.EE]: mapStepsConfigToBackgroundInfoEvents(eeStepsConfig),
    [CountryChannel.FI]: mapStepsConfigToBackgroundInfoEvents(fiStepsConfig),
    [CountryChannel.LT]: mapStepsConfigToBackgroundInfoEvents(ltStepsConfig),
    [CountryChannel.LV]: mapStepsConfigToBackgroundInfoEvents(lvStepsConfig),
    [CountryChannel.SE]: mapStepsConfigToBackgroundInfoEvents(seStepsConfig),
    [CountryChannel.NL]: mapStepsConfigToBackgroundInfoEvents(cbStepsConfig),
    [CountryChannel.DE]: mapStepsConfigToBackgroundInfoEvents(cbStepsConfig),
    [CountryChannel.AT]: mapStepsConfigToBackgroundInfoEvents(cbStepsConfig)
};
