<template>
  <aml-and-screening-thank-you v-if="showAmlAndScreeningThankYouPage" />
  <thank-you v-else />
</template>

<script>
import { useAppStore } from '@/store/app.store'
import AmlAndScreeningThankYou from './AmlAndScreeningThankYou.vue'
import ThankYou from './ThankYou.vue'
import { mapState } from 'pinia'

export default {
  components: {
    AmlAndScreeningThankYou,
    ThankYou
  },
  computed: {
    ...mapState(useAppStore, ['status']),
    showAmlAndScreeningThankYouPage () {
      return this.status.waitingForAmlAndScreeningCheck
    }
  }
}
</script>
