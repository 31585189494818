<script>
import { mapActions } from 'pinia'
import RegistrationMixin from '@/views/common/RegistrationMixin.vue'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'registration-signed',
  render: () => null,
  mixins: [
    RegistrationMixin
  ],
  data () {
    return {
      statusPollingReference: null
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setLoader', 'getStatus']),
    pollStatus () {
      this.statusPollingReference = setTimeout(() => {
        this.getStatus({ showLoader: true })
          .then((status) => {
            if (status?.hasCrmInsertionError) {
              this.setError({
                redirect: true
              })
            }
          })
          .catch(() => {
            // Ignore errors
          })
          .then(() => this.pollStatus())
      }, 1000)
    }
  },
  beforeDestroy () {
    clearTimeout(this.statusPollingReference)
  },
  async created () {
    await this.setLoader({
      isLoading: true,
      loadingText: this.$pgettext('LV_application_confirmation', 'Confirming signature...')
    })

    try {
      await this.partialRegistration({})
      // Poll status every second as submitting registration will start task processing, ending with order to redirect to self service
      this.pollStatus()
    } catch (err) {
      this.handleError(err)
    }
  }
}
</script>
