<template>
  <component :is="currentView"/>
</template>

<script>
import PersonalDetails from './PersonalAndCompanyDetails.vue'
import AdditionalDetails from './AdditionalDetails.vue'
import AddressDetails from './AddressDetails.vue'
import CommonRegistration from '@/views/common/CommonRegistration.vue'
import { mapActions, mapState } from 'pinia'
import { useAppStore } from '@/store/app.store'
import stepsConfig from '@/views/lv/steps.config'
const { CUSTOMER_TYPE } = require('@/const')

export default {
  components: {
    PersonalDetails,
    AdditionalDetails,
    AddressDetails
  },
  computed: {
    ...mapState(useAppStore, ['featureFlags']),
    views () {
      return stepsConfig.legal
    }
  },
  methods: {
    ...mapActions(useAppStore, ['setCustomerType', 'setError'])
  },
  created () {
    this.setCustomerType(CUSTOMER_TYPE.Legal)
  },
  beforeRouteEnter (_to, _from, next) {
    next(vm => {
      if (!vm.featureFlags.enableLegalOnboarding) {
        vm.setError({
          code: '404',
          redirect: true
        })
      }
    })
  },
  extends: CommonRegistration
}
</script>
