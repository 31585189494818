export default class RequestError extends Error {
    type;
    correlationId;
    data; // eslint-disable-line @typescript-eslint/no-explicit-any
    constructor(message, { type, correlationId, data }) {
        super(message);
        this.type = type;
        this.correlationId = correlationId;
        this.data = data;
    }
}
