<template>
  <personal-details
      :field-definitions="fieldDefinitions"
      :phone-number-prefix="phoneNumberPrefix"
      :channel="channel"
      :locale="language"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
      :personalIdentityCodeMask="personalIdentityCodeMask"
  ></personal-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import PersonalDetails from '@/views/common/PersonalDetails.vue'
import * as idNumberValidator from '@bigbank/dc-common/validators/cb/taxIdNumber'
import * as phoneNumberValidator from '@bigbank/dc-common/validators/cb/phoneNumber'
import { cleanInput } from '@/views/common/util'
import { CountryChannel } from '@bigbank/dc-common/config'
import {
  FormValidation,
  getTINInputMask,
  isTINAndPersonalIdentificationCodeTheSame
} from '@bigbank/dc-common/validators/validation.config'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'personal-details-cb',
  components: {
    PersonalDetails
  },
  data () {
    return {
      isLoading: true,
      form: {
        personTitle: undefined,
        academicTitle: undefined,
        personalIdentityCode: undefined,
        firstName: undefined,
        surname: undefined,
        phoneNumber: undefined,
        marketingConsent: false,
        email: undefined
      },
      hasAcademicTitle: false,
      marketingConsentVisible: false,
      fieldDefinitions: [
        { name: 'personTitle' },
        {
          name: 'academicTitle',
          showField: () => {
            return [CountryChannel.DE, CountryChannel.AT].includes(this.channel)
          }
        },
        { name: 'firstName' },
        { name: 'surname' },
        {
          name: 'personalIdentityCode',
          showField: () => {
            return isTINAndPersonalIdentificationCodeTheSame(this.channel)
          },
          validator: {
            message: this.$pgettext('registration', 'Please insert valid ID number'),
            validate: value => {
              return this.channel === CountryChannel.NL && idNumberValidator(value, this.channel)
            }
          }
        },
        { name: 'email' },
        {
          name: 'phoneNumber',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid phone number'),
            validate: value => {
              return phoneNumberValidator(cleanInput(value), this.channel)
            }
          }
        },
        { name: 'marketingConsent' },
        { name: 'crossBorderConsent' }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language']),
    personalIdentityCodeMask () {
      return getTINInputMask(FormValidation.OnBoardingFlow, this.channel) ?? ''
    },
    phoneNumberPrefix () {
      switch (this.channel) {
        case CountryChannel.NL:
          return '+31'
        case CountryChannel.DE:
          return '+49'
        case CountryChannel.AT:
          return '+43'
        default:
          return ''
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep'])
  }
}
</script>
