<template>
  <bb-application-page
      :form-props="{
        uppercaseButtons: false,
        errorText: translations.errors.errorText,
        submitText: translations.submitText,
        disabled: isLoading,
        loading: isLoading,
      }"
      @submit="onSubmit"
    >
      <template #header>
        <bb-public-header
          :title="translations.header"
          icon="client-personal"
        />
      </template>
    <p v-if="errorMessage" class="f-color-red f-align-center f-gotham f-small m-t-10 m-b-20">
      {{errorMessage}}
    </p>
    <bb-input
      v-model="form.registrationAddress.streetAddress"
      v-validate="'required'"
      :label="translations.streetAddress"
      :data-vv-as="translations.streetAddress"
      name="registrationStreetAddress"
    />
    <bb-input
      v-model="form.registrationAddress.city"
      v-validate="'required'"
      :label="translations.city"
      :data-vv-as="translations.city"
      name="registrationCity"
    />
    <bb-input
      v-model="form.registrationAddress.county"
      :label="translations.contactCounty"
      :data-vv-as="translations.contactCounty"
      name="registrationCounty"
    />
    <bb-masked-input
      v-model="form.registrationAddress.postcode"
      v-validate="'required|lv_postal_code'"
      :mask="'L\\V-1111'"
      :label="translations.postalCode"
      :data-vv-as="translations.postalCode"
      name="registrationPostalCode"
    />
    <bb-select
      readonly
      v-model="form.registrationAddress.country"
      v-validate="'required'"
      :options="countryOptionsWithExcludedStateless"
      :label="translations.country"
      :data-vv-as="translations.country"
      :disabled="true"
      :helpText="translations.countryTooltip"
      :helpActive="true"
      name="registrationCountry"
    />
    <bb-checkbox
      :checked="isSameAddress"
      name="registrationSameAsContactAddress"
      :label="translations.isSameAddress"
      v-model="isSameAddress"
      data-vv-validate-on="change"
    >{{ translations.sameAddress }}</bb-checkbox>
    <template v-if="!isSameAddress">
      <bb-input
        v-model="form.contactAddress.streetAddress"
        v-validate="'required'"
        :label="translations.contactStreetAddress"
        :data-vv-as="translations.contactStreetAddress"
        name="contactStreetAddress"
      />
      <bb-input
        v-model="form.contactAddress.city"
        v-validate="'required'"
        :label="translations.contactCity"
        :data-vv-as="translations.contactCity"
        name="contactCity"
      />
      <bb-input
        v-model="form.contactAddress.county"
        :label="translations.contactCounty"
        :data-vv-as="translations.contactCounty"
        name="contactCounty"
      />
      <bb-input
        :value="form.contactAddress.postcode"
        @input="handleContactPostcodeChange"
        v-validate="'required|lv_contact_postal_code'"
        :label="translations.contactPostalCode"
        :data-vv-as="translations.contactPostalCode"
        name="contactPostalCode"
      />
      <bb-select
        v-model="form.contactAddress.country"
        v-validate="'required'"
        :options="countryOptionsWithExcludedStateless"
        :label="translations.contactCountry"
        :data-vv-as="translations.contactCountry"
        @change="onContactCountryChange"
        name="contactCountry"
      />
    </template>
  </bb-application-page>
</template>

<script>
import RegistrationMixin from '../common/RegistrationMixin.vue'
import { cleanInput } from '../common/util'
import {
  BbApplicationPage,
  BbPublicHeader,
  BbInput,
  BbCheckbox,
  BbSelect,
  BbMaskedInput
} from '@bigbank/interface-components'
import { mapActions, mapState } from 'pinia'
import errorHandler from './../../errors/errorHandler'
import * as postalCodeValidator from '@bigbank/dc-common/validators/lv/postalCode'
import { STATELESS_COUNTRY_CODE } from '@/const'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'lv-address-details',
  components: {
    BbApplicationPage,
    BbPublicHeader,
    BbInput,
    BbCheckbox,
    BbSelect,
    BbMaskedInput
  },
  mixins: [
    RegistrationMixin
  ],
  data () {
    return {
      isLoading: true,
      isSameAddress: true,
      form: {
        registrationAddress: {
          streetAddress: undefined,
          city: undefined,
          county: undefined,
          postcode: undefined,
          country: 'LV'
        },
        contactAddress: {
          streetAddress: undefined,
          city: undefined,
          county: undefined,
          postcode: undefined,
          country: 'LV'
        }
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['formData', 'countries', 'isV2Flow']),
    translations () {
      return {
        submitText: this.$pgettext('registration', 'SUBMIT_FORM_BUTTON_TEXT'),
        header: this.$pgettext('registration', 'Address Details'),
        streetAddress: this.$pgettext('registration', 'Street & house'),
        city: this.$pgettext('registration', 'City'),
        postalCode: this.$pgettext('registration', 'Postal code'),
        country: this.$pgettext('registration', 'Country'),
        contactStreetAddress: this.$pgettext('registration', 'Mailing Street & house'),
        contactCity: this.$pgettext('registration', 'Mailing City'),
        contactCounty: this.$pgettext('registration', 'County'),
        contactPostalCode: this.$pgettext('registration', 'Mailing Postal code'),
        contactCountry: this.$pgettext('registration', 'Mailing Country'),
        countryTooltip: this.$pgettext('registration', 'COUNTRY_HELP_TEXT'),
        sameAddress: this.$pgettext('registration', 'My postal address is the same as the registered address.'),
        errors: {
          invalidPostalCode: this.$pgettext('registration', 'Postal code has invalid format'),
          errorText: this.$pgettext('registration', 'FORM_HAS_ERRORS_MESSAGE')
        }
      }
    },
    countryOptionsWithExcludedStateless () {
      return this.getCountriesList([STATELESS_COUNTRY_CODE])
    }
  },
  methods: {
    ...mapActions(useAppStore, ['getStatus', 'updateFormData', 'goToNextStep', 'getCountries']),
    getCountriesList (excludeByCode) {
      let countries = this.countries.map(country => ({
        value: country.countryCode,
        text: country.translation
      }))
        .sort((a, b) => a.text.localeCompare(b.text, this.locale))

      if (Array.isArray(excludeByCode)) {
        countries = countries.filter((country) => !excludeByCode.includes(country.value))
      }

      return countries
    },
    async onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      try {
        if (this.isSameAddress) {
          this.form.contactAddress = { ...this.form.registrationAddress }
        }
        await this.updateFormData({ addressDetails: this.form })
        await this.registerFormData(this.formData)
        await this.getStatus()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    handleContactPostcodeChange (value) {
      this.form.contactAddress.postcode = cleanInput(value)
    },
    onContactCountryChange () {
      this.$validator.validate('contactPostalCode')
    }
  },
  async created () {
    try {
      this.$validator.extend('lv_postal_code', {
        getMessage: () => this.translations.errors.invalidPostalCode,
        validate: value => postalCodeValidator(value)
      })
      this.$validator.extend('lv_contact_postal_code', {
        getMessage: () => this.translations.errors.invalidPostalCode,
        validate: value => {
          if (this.form.contactAddress.country === 'LV') {
            return postalCodeValidator(value)
          }
          return value.trim().length >= 1
        }
      })

      await this.getCountries()
      this.isLoading = false
    } catch (err) {
      errorHandler(err)
    }
  }
}
</script>
