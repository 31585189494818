<template>
  <personal-details
      :field-definitions="fieldDefinitions"
      :phone-number-prefix="phoneNumberPrefix"
      :channel="channel"
      :locale="language"
      :date-format="dateFormat"
      :translations-overrides="translations"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
      :personal-identity-code-mask="personalIdentityCodeMask"
  ></personal-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import PersonalDetails from '@/views/common/PersonalDetails.vue'
import * as idNumberValidator from '@bigbank/dc-common/validators/lv/idNumber'
import * as phoneNumberValidator from '@bigbank/dc-common/validators/lv/phoneNumber'
import { LVValidator } from '@bigbank/dc-common/validators/lv/LVValidator.class'
const validator = new LVValidator()
import { cleanInput } from '@/views/common/util'
import dayjs from 'dayjs'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'personal-and-company-details-lv-legal',
  components: {
    PersonalDetails
  },
  data () {
    return {
      isLoading: true,
      phoneNumberPrefix: '+371',
      personalIdentityCodeMask: '111111-11111',
      dateFormat: 'DD.MM.YYYY',
      form: {
        personalIdentityCode: undefined,
        companyName: undefined,
        companyRegistrationCode: undefined,
        businessActivityCode: undefined,
        bankAccountNumber: undefined,
        firstName: undefined,
        surname: undefined,
        dateOfBirth: undefined,
        phoneNumber: undefined,
        phoneNumberPrefix: undefined,
        marketingConsent: false,
        externalRegistryCheckConsent: null,
        email: undefined
      },
      hasAcademicTitle: false,
      marketingConsentVisible: true,
      fieldDefinitions: [
        { name: 'companyName' },
        { name: 'companyRegistrationCode' },
        { name: 'businessActivityCode' },
        {
          name: 'bankAccountNumber',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid IBAN account number'),
            validate: value => validator.isValidIBAN(value, true)
          }
        },
        { name: 'firstName' },
        { name: 'surname' },
        {
          name: 'personalIdentityCode',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid ID number'),
            validate: value => idNumberValidator(value)
          }
        },
        {
          name: 'dateOfBirth',
          showField: form => {
            const idNumber = form.personalIdentityCode ?? ''
            const isNewFormat = validator.isIdNumberInNewFormat(idNumber)

            if (!isNewFormat && idNumber) {
              const birthDate = dayjs(validator.getBirthdayDateStringYYYYMMDD(idNumber), 'YYYY-MM-DD', true)
              if (birthDate.isValid()) {
                form.dateOfBirth = birthDate.format(this.dateFormat)
              }
            }

            return isNewFormat
          }
        },
        { name: 'email' },
        {
          name: 'phoneNumber',
          validator: {
            message: this.$pgettext('registration', 'Please insert valid phone number'),
            validate: value => {
              try {
                return phoneNumberValidator(cleanInput(value), this.channel)
              } catch (err) {
                return false
              }
            }
          }
        },
        { name: 'marketingConsent' },
        { name: 'dataProcessingSilentConsent' }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language']),
    translations () {
      return {
        header: this.$pgettext('registration_legal', 'Personal and company details'),
        subtitle: this.$pgettext('registration_legal', 'Please fill this form below to open new deposit for the company you represent')
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep'])
  }
}
</script>
