import { defineStore } from 'pinia';
import { isNil } from 'lodash';
import { useAppStore } from './app.store';
import api from '../api';
export const useAnalyticsStore = defineStore('analytics', {
    state: () => ({
        tracking: {
            userAgent: undefined,
            googleAnalyticsCustomerId: undefined,
            googleAnalyticsSessionId: undefined,
            screenResolution: undefined,
            viewportSize: undefined,
            gclid: undefined,
            gclidExpiryDate: undefined,
            wbraid: undefined,
            wbraidExpiryDate: undefined
        }
    }),
    getters: {
        trackingMetadata(state) {
            return state.tracking;
        }
    },
    actions: {
        async sendAnalyticsEvent({ pageUrlPath, eventName }) {
            try {
                const requiredTrackingFields = [
                    'userAgent',
                    'screenResolution',
                    'viewportSize'
                ];
                const hasTrackingMetadata = Object.keys(this.tracking)
                    .filter(key => requiredTrackingFields.includes(key))
                    .every(key => !isNil(this.tracking[key]));
                if (hasTrackingMetadata) {
                    const main = useAppStore();
                    const metadata = {
                        pageUrlPath,
                        eventName,
                        customerType: main.customerType,
                        product: main.productType,
                        ...this.tracking
                    };
                    await api.trackEvent(metadata);
                }
            }
            catch (err) {
                console.error(err);
            }
        },
        async updateTrackingMetadata(metadata) {
            if (!metadata.screenResolution) {
                const { width, height } = window.screen;
                const { devicePixelRatio = 1 } = window;
                metadata.screenResolution = `${width * devicePixelRatio}x${height * devicePixelRatio}`;
            }
            if (!metadata.viewportSize) {
                const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
                const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
                metadata.viewportSize = `${vw}x${vh}`;
            }
            this.tracking = { ...metadata };
        }
    }
});
