const stepsConfigByFlow = Object.freeze({
    legal: Object.freeze([
        'personal-details',
        'additional-details',
        'address-details'
    ]),
    private: Object.freeze([
        'personal-details',
        'additional-details',
        'address-details'
    ]),
    currentAccount: Object.freeze([
        'personal-details',
        'address-details',
        'additional-details'
    ])
});
export default stepsConfigByFlow;
