<template>
  <div class="layout">
    <bb-navbar
      flavor="public"
      :logo="logo"
      :logoTo="homeUrl"
      :login="loginButton"
      :progressBar="progressBarComputed"
      :languages="languages"
      @languageClick="switchLanguage"
    >
    </bb-navbar>
    <div class="layout__content_wrapper">
      <slot />
      <bb-loading-overlay
        :loadingDelay="0"
        :text="loadingOverlayText"
        :is-loading="isLoadingOverlayShown || isLoading"
        color="white"
      />
    </div>
    <div class="layout__footer_wrapper">
      <footer-component class="layout__footer" />
    </div>
    <bb-back-navigation-modal
      :queryParamsToCheck="['lang']"
      :buttonLabel="translations.navigationModalButtonLabel"
      :description="translations.navigationModalDescription"
    />
  </div>
</template>

<script>
import { BbNavbar, BbLoadingOverlay, BbBackNavigationModal } from '@bigbank/interface-components'
import logo from '@bigbank/interface-components/dist/img/bigbank-dark@2x.png'
import { mapState, mapActions } from 'pinia'
import Footer from './Footer.vue'
import { translationPicker } from './../util/translation'
import { useAppStore } from '@/store/app.store'

export default {
  components: {
    BbNavbar,
    BbLoadingOverlay,
    FooterComponent: Footer,
    BbBackNavigationModal
  },
  data () {
    return {
      logo
    }
  },
  props: {
    progressBar: {
      required: false,
      default: undefined
    },
    hideLoginButton: {
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState(useAppStore, ['channelConfig', 'status', 'isLoading', 'loadingText', 'language', 'flowType', 'supportedLanguages', 'isLoadingOverlayShown']),
    config () {
      return this.channelConfig
    },
    loadingOverlayText () {
      return this.isLoadingOverlayShown ? this.translations.waitLongerThanUsualText : this.loadingText
    },
    translations () {
      return {
        login: this.$pgettext('navbar', 'Log in'),
        navigationModalButtonLabel: this.$pgettext('navigation_modal', 'OK'),
        navigationModalDescription: this.$pgettext('navigation_modal', 'Please continue with your current application. If you have questions, then contact our customer service.'),
        waitLongerThanUsualText: this.$pgettext('loading-overlay', 'This takes longer than usual. Please hold on.')
      }
    },
    languages () {
      return this.supportedLanguages.length > 1 ? this.supportedLanguages : null
    },
    loginButton () {
      if (this.hideLoginButton) {
        return undefined
      }

      return {
        label: this.translations.login,
        to: `${this.config.selfserviceUrl}?language=${this.language}`
      }
    },
    homeUrl () {
      return translationPicker(this.config.homeUrl, this.language)
    },
    progressBarComputed () {
      return this.$route?.meta?.hideProgressbar ? undefined : this.progressBar
    }
  },
  methods: {
    ...mapActions(useAppStore, ['changeLanguage']),
    async switchLanguage (lang) {
      const url = new URL(window.location)
      url.searchParams.set('lang', lang.key)
      window.history?.pushState({}, '', url)
      await this.changeLanguage(lang.key)
    }
  }
}
</script>

<style lang="scss" scoped>
  .layout {
    display: flex;
    align-content: stretch;
    min-height: 100vh;
    flex-direction: column;

    &__content_wrapper {
      overflow-y: auto;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    &__footer_wrapper {
      margin-top: auto;
    }

    &__footer {
      margin-top: 30px;
    }
  }
</style>
