<template>
  <additional-details
      :field-definitions="fieldDefinitions"
      :date-format="dateFormat"
      :channel="channel"
      :locale="language"
      :headerText="translations.headerText"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
  ></additional-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import AdditionalDetails from '@/views/common/AdditionalDetails.vue'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'additional-details-lv-legal',
  components: {
    AdditionalDetails
  },
  data () {
    return {
      isLoading: true,
      hasDualCitizenship: false,
      form: {
        fieldOfActivity: undefined,
        customFieldOfActivity: undefined,
        placeOfBirth: undefined,
        taxIdNumber: undefined,
        taxResidency: undefined,
        citizenship: undefined,
        dualCitizenship: undefined,
        isPep: undefined
      },
      dateFormat: 'DD.MM.YYYY',
      fieldDefinitions: [
        { name: 'fieldOfActivity' },
        { name: 'customFieldOfActivity' },
        { name: 'citizenship', disablePrefill: true },
        { name: 'dualCitizenship' },
        { name: 'placeOfBirth', disablePrefill: true },
        { name: 'taxResidency', disablePrefill: true },
        { name: 'taxIdNumber', showField: form => form.taxResidency !== this.channel },
        { name: 'isPep' },
        {
          name: 'isPepReason',
          showField: form => {
            return form.isPep === 'YES'
          }
        }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language', 'formData']),
    translations () {
      return {
        headerText: this.$pgettext('registration', 'Legal representative additional details')
      }
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep'])
  }
}
</script>
