<template>
  <signing-modal-wrapper
      :visible="visible"
      @close="$emit('close', $event)"
      :showLoader="isLoading">
    <template #heading>{{translations.title}}</template>
    <p v-if="showErrorMessage" class="error">
      <signing-error-messages
        :code="errorCode"
        :type="signingMethod"
        :visible="showErrorMessage"
        :data="errorData"
        @close="closeModal"
      />
    </p>
    <signature-verification-modal
      v-else
      :visible="isModalVisible"
      @close="closeModal"
      :challengeId="challengeId"
      :errorCode="errorCode"
      :type="signingMethod"/>
  </signing-modal-wrapper>
</template>

<script>
import { AuthMethod } from '@bigbank/dc-common/config'
import SigningErrorMessages from './SigningErrorMessages.vue'
import SignatureVerificationModal from './SignatureVerificationModal.vue'
import SigningModalWrapper from './SigningModalWrapper.vue'
import { isFunction, isNull, isString } from 'lodash'
import { VERIFICATION_METHODS } from './constants'

export default {
  name: 'smart-id-signing',
  components: { SignatureVerificationModal, SigningErrorMessages, SigningModalWrapper },
  data () {
    return {
      isModalVisible: false,
      challengeId: '',
      isLoading: false,
      errorCode: null,
      errorData: null,
      signingMethod: AuthMethod.SMART_ID
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    startSigning: {
      type: Function,
      required: true
    },
    completeSigning: {
      type: Function,
      required: true
    }
  },
  computed: {
    translations () {
      return {
        title: this.$pgettext('signing_smartid', 'Signature with Smart-ID'),
        loading: this.$pgettext('signing_loading', 'This will take a moment, please hold tight!')
      }
    },
    showErrorMessage () {
      return !isNull(this.errorCode)
    }
  },
  methods: {
    setErrorCode (code) { this.errorCode = code },
    toggleModalVisibility (flag) { this.isModalVisible = flag },
    setLoading (flag) { this.isLoading = flag },
    setChallengeId (challengeId) { this.challengeId = challengeId },
    async pollStatus (delayMs = VERIFICATION_METHODS.SMART_ID.POLL_DELAY_MS) {
      if (!this.isModalVisible) return

      try {
        const response = await this.completeSigning()

        if (response.status === VERIFICATION_METHODS.SMART_ID.STATUS.COMPLETED_OK) {
          this.toggleModalVisibility(false)
          this.$emit('signingSuccessful')
        } else if (response.status === VERIFICATION_METHODS.SMART_ID.STATUS.RUNNING) {
          setTimeout(() => this.pollStatus(), delayMs)
        } else {
          throw response
        }
      } catch (response) {
        this.setErrorCode(response.type ?? 'DEFAULT')
      }
    },
    async startSigningProcess (onChallengeIdReceived) {
      this.setLoading(true)
      this.setErrorCode(null)

      try {
        const response = await this.startSigning()

        if (isString(response.challengeId)) {
          this.setChallengeId(response.challengeId)
          this.toggleModalVisibility(true)
          this.setLoading(false)
          isFunction(onChallengeIdReceived) && await onChallengeIdReceived()
        } else {
          throw response
        }
      } catch (response) {
        this.setLoading(false)
        this.setErrorCode(response.type ?? 'DEFAULT')
      }
    },
    closeModal () {
      this.toggleModalVisibility(false)
      this.setErrorCode(null)
      this.$emit('close')
    }
  },
  mounted () {
    this.startSigningProcess(async () => {
      await this.pollStatus()
    })
  }
}
</script>

<style lang="scss" scoped>

.error {
  color: $red;
  font-family: $gotham-medium;
  margin-top: 20px;
  text-align: center;
  font-size: $font-size-smallest;
}
</style>
