import { defineStore } from 'pinia';
import { additionalDetailsTemplate, addressTemplate, personalDetailsTemplate } from './store.util';
export const useCrossBorderStore = defineStore('crossBorder', {
    state: () => ({
        privateForm: {
            personalDetails: {
                ...personalDetailsTemplate(),
                personTitle: undefined,
                academicTitle: undefined
            },
            additionalDetails: additionalDetailsTemplate(),
            registrationAddressDetails: addressTemplate()
        }
    })
});
