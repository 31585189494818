export const VERIFICATION_METHODS = {
    SMART_ID: {
        STATUS: {
            COMPLETED_OK: 'COMPLETED_OK',
            RUNNING: 'RUNNING'
        },
        POLL_DELAY_MS: 3000
    },
    EPARAKSTS: {
        STATUS: {
            COMPLETED_OK: 'COMPLETED_OK',
            RUNNING: 'RUNNING'
        },
        POLL_DELAY_MS: 1000
    }
};
