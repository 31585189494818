import { defineStore } from 'pinia';
import { additionalDetailsTemplate, personalDetailsTemplate, addressTemplate } from './store.util';
export const useLatviaStore = defineStore('latvia', {
    state: () => ({
        legalForm: {
            personalDetails: {
                personalIdentityCode: undefined,
                dateOfBirth: undefined,
                companyName: undefined,
                companyRegistrationCode: undefined,
                businessActivityCode: undefined,
                bankAccountNumber: undefined,
                firstName: undefined,
                surname: undefined,
                phoneNumber: undefined,
                phoneNumberPrefix: undefined,
                marketingConsent: false,
                email: undefined
            },
            additionalDetails: {
                fieldOfActivity: undefined,
                customFieldOfActivity: undefined,
                placeOfBirth: undefined,
                taxIdNumber: undefined,
                taxResidency: undefined,
                citizenship: undefined,
                dualCitizenship: undefined,
                isPep: undefined
            },
            addressDetails: {
                registrationAddress: addressTemplate(),
                contactAddress: addressTemplate()
            }
        },
        privateForm: {
            personalDetails: personalDetailsTemplate({
                externalRegistryCheckConsent: null,
                phonePassword: undefined
            }),
            additionalDetails: additionalDetailsTemplate(),
            addressDetails: {
                registrationAddress: addressTemplate(),
                contactAddress: addressTemplate()
            }
        }
    })
});
