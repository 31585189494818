<template>
  <additional-details
    :field-definitions="fieldDefinitions"
    :date-format="dateFormat"
    :channel="channel"
    :locale="language"
    :update-form-data="updateFormData"
    :go-to-next-step="goToNextStep"
  />
</template>

<script>
import { mapActions, mapState } from 'pinia'
import AdditionalDetails from '@/views/common/AdditionalDetails.vue'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'additional-details-lv',
  components: {
    AdditionalDetails
  },
  data () {
    return {
      isLoading: true,
      hasDualCitizenship: false,
      form: {
        fieldOfActivity: undefined,
        customFieldOfActivity: undefined,
        placeOfBirth: undefined,
        taxIdNumber: undefined,
        taxResidency: undefined,
        citizenship: undefined,
        dualCitizenship: undefined,
        isPep: undefined
      },
      dateFormat: 'DD.MM.YYYY'
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language', 'isV2Flow', 'formData']),
    fieldDefinitions () {
      return [
        { name: 'fieldOfActivity' },
        { name: 'customFieldOfActivity' },
        {
          name: 'dateOfBirth',
          showField: (form) => {
            if (this.isV2Flow) {
              return false
            }

            let show = this.isNewIdFormat(this.formData.personalIdentityCode)
            if (!show && !form.dateOfBirth) {
              form.dateOfBirth = this.extractDateOfBirth(this.formData.personalIdentityCode)
            }

            if (!form.dateOfBirth) {
              show = true
            }

            return show
          }
        },
        {
          name: 'placeOfBirth',
          disablePrefill: !this.isV2Flow
        },
        {
          name: 'taxResidency',
          disablePrefill: !this.isV2Flow
        },
        {
          name: 'taxIdNumber',
          showField: form => {
            return form.taxResidency !== this.channel
          }
        },
        {
          name: 'citizenship',
          disablePrefill: !this.isV2Flow
        },
        { name: 'dualCitizenship' },
        { name: 'isPep' },
        {
          name: 'isPepReason',
          showField: form => {
            return form.isPep === 'YES'
          }
        }
      ]
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep']),
    extractDateOfBirth (personalIdentityCode) {
      if (personalIdentityCode) {
        const centuryMap = {
          0: '18',
          1: '19',
          2: '20'
        }

        const day = personalIdentityCode.slice(0, 2)
        const month = personalIdentityCode.slice(2, 4)
        const yearSuffix = personalIdentityCode.slice(4, 6)
        const centuryCode = personalIdentityCode.charAt(7)
        const year = centuryMap[centuryCode] + yearSuffix

        return `${day}.${month}.${year}`
      }

      return undefined
    },
    isNewIdFormat (personalIdentityCode) {
      if (personalIdentityCode) {
        return personalIdentityCode.slice(0, 2) === '32'
      }

      return false
    }
  }
}
</script>
