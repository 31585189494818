<template>
  <div class="download-app__container">
    <div class="download-app__google-play-badge">
      <a :href="googlePlayStoreLink" target="_blank">
        <!--suppress HtmlUnknownTarget -->
        <img :src="googlePlayBadge"/>
        <img :src="googlePlayQr" class="download-qr"/>
      </a>
    </div>
    <div class="download-app__app-store-badge">
      <a :href="appleAppStoreLink" target="_blank">
        <img :src="appStoreBadge"/>
        <img :src="appStoreQr" class="download-qr"/>
      </a>
    </div>
  </div>
</template>

<script>
import appStoreBadgeBg from '../images/apple/app-store-badge-bg.svg'
import appStoreBadgeDe from '../images/apple/app-store-badge-de.svg'
import appStoreBadgeEn from '../images/apple/app-store-badge-en.svg'
import appStoreBadgeEt from '../images/apple/app-store-badge-et.svg'
import appStoreBadgeFi from '../images/apple/app-store-badge-fi.svg'
import appStoreBadgeLt from '../images/apple/app-store-badge-lt.svg'
import appStoreBadgeLv from '../images/apple/app-store-badge-lv.svg'
import appStoreBadgeNl from '../images/apple/app-store-badge-nl.svg'
import appStoreBadgeRu from '../images/apple/app-store-badge-ru.svg'
import appStoreBadgeSv from '../images/apple/app-store-badge-sv.svg'
import googlePlayBadgeBg from '../images/google/google-play-badge-bg.png'
import googlePlayBadgeDe from '../images/google/google-play-badge-de.png'
import googlePlayBadgeEn from '../images/google/google-play-badge-en.png'
import googlePlayBadgeEt from '../images/google/google-play-badge-et.png'
import googlePlayBadgeFi from '../images/google/google-play-badge-fi.png'
import googlePlayBadgeLt from '../images/google/google-play-badge-lt.png'
import googlePlayBadgeLv from '../images/google/google-play-badge-lv.png'
import googlePlayBadgeNl from '../images/google/google-play-badge-nl.png'
import googlePlayBadgeRu from '../images/google/google-play-badge-ru.png'
import googlePlayBadgeSv from '../images/google/google-play-badge-sv.png'
import googlePlayQr from '../images/qrcode_playstore.png'
import appStoreQr from '../images/qrcode_appstore.png'

function capitalize (s) {
  return s[0].toUpperCase() + s.slice(1)
}

const languageMap = {
  us: 'en',
  uk: 'en',
  ee: 'et',
  se: 'sv'
}

export default {
  name: 'download-app',
  props: {
    lang: {
      type: String,
      default: 'en'
    },
    googlePlayStoreLink: String,
    appleAppStoreLink: String
  },
  data () {
    return {
      appStoreBadgeBg,
      appStoreBadgeDe,
      appStoreBadgeEn,
      appStoreBadgeEt,
      appStoreBadgeFi,
      appStoreBadgeLt,
      appStoreBadgeLv,
      appStoreBadgeNl,
      appStoreBadgeRu,
      appStoreBadgeSv,
      googlePlayBadgeBg,
      googlePlayBadgeDe,
      googlePlayBadgeEn,
      googlePlayBadgeEt,
      googlePlayBadgeFi,
      googlePlayBadgeLt,
      googlePlayBadgeLv,
      googlePlayBadgeNl,
      googlePlayBadgeRu,
      googlePlayBadgeSv,
      appStoreQr,
      googlePlayQr
    }
  },
  computed: {
    language () {
      return this.lang ? this.lang.toLowerCase().slice(0, 2) : 'en'
    },
    appStoreBadge () {
      const lang = languageMap[this.language] || this.language
      return this['appStoreBadge' + capitalize(lang)]
    },
    googlePlayBadge () {
      const lang = languageMap[this.language] || this.language
      return this['googlePlayBadge' + capitalize(lang)]
    }
  }
}
</script>

<style lang="scss" scoped>
  .download-app {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 12px;

      @media (min-width: $breakpoint-sm - 300) {
        flex-direction: row;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;

        @media (min-width: $breakpoint-sm - 300) {
          width: 50%;
          margin: 0;
        }

        @media (min-width: $breakpoint-md) {
          background: $gray-20;
          border: 1px solid $gray-30;
          border-radius: 8px;
          padding: 16px;
        }

        img {
          width: 100%;
          display: block;
        }
      }

      .download-qr {
        border: 1px solid $gray-30;
        border-radius: 8px;
        margin-top: 11px;
        display: none;

        @media (min-width: $breakpoint-md) {
          display: block;
        }
      }
    }
  }
</style>
