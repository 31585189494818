import VueRouter from 'vue-router';
import * as app from '@/store/app.store';
import bgRouter from './bg/routes';
import cbRouter from './cb/routes';
import lvRouter from './lv/routes';
import fiRouter from './fi/routes';
import seRouter from './se/routes';
import eeRouter from './ee/routes';
import ltRouter from './lt/routes';
import ErrorView from './ErrorView.vue';
const router = new VueRouter({
    routes: [{
            name: 'error',
            component: ErrorView,
            path: '/error'
        }, {
            path: '*',
            beforeEnter(_to, _from, next) {
                const appStore = app.useAppStore();
                appStore.setError({
                    code: '404'
                });
                next({ name: 'error' });
            }
        }],
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});
// setRouter(router)
switch (window.CHANNEL) {
    case 'BG':
        bgRouter(router);
        break;
    case 'FI':
        fiRouter(router);
        break;
    case 'SE':
        seRouter(router);
        break;
    case 'LV':
        lvRouter(router);
        break;
    case 'EE':
        eeRouter(router);
        break;
    case 'LT':
        ltRouter(router);
        break;
    case 'NL':
    case 'AT':
    case 'DE':
        cbRouter(router);
        break;
}
export default router;
