<template>
  <common-layout
    :progress-bar="progressBar"
    :hide-login-button="isFlowSelectionPageEnabled"
  >
    <router-view />
  </common-layout>
</template>

<script>
import { mapState } from 'pinia'
import RoutingMixin from '../common/RoutingMixin.vue'
import CommonLayout from './../../components/CommonLayout.vue'
import { useAppStore } from '@/store/app.store'

export default {
  mixins: [
    RoutingMixin
  ],
  components: {
    CommonLayout
  },
  computed: {
    ...mapState(useAppStore, ['currentStep', 'status', 'isV2Flow']),
    isFlowSelectionPageEnabled () {
      return this.isV2Flow
    },
    hasRegistrationStatus () {
      return !!this.status.status
    },
    progressBar () {
      if (
        !this.isFlowSelectionPageEnabled ||
        (this.isFlowSelectionPageEnabled && this.hasRegistrationStatus)
      ) {
        return {
          max: 4,
          value: this.currentStep + 1,
          color: 'blue'
        }
      }

      return null
    }
  }
}
</script>
