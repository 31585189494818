export const STATELESS_COUNTRY_CODE = 'XA'; // TODO: When you will change dc-common add this to the constants
export const CUSTOMER_TYPE = Object.freeze({
    Private: 'private',
    Legal: 'legal'
});
export const PRODUCT_TYPE = Object.freeze({
    Loan: 'loan',
    Deposit: 'deposit',
    CurrentAccount: 'current_account'
});
export const BG_IDENTIFICATION_STATUSES = Object.freeze({
    UNKNOWN: 'UNKNOWN',
    IDENTIFIED: 'IDENTIFIED', // deprecated,
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    EXISTING_CUSTOMER: 'EXISTING_CUSTOMER',
    NOT_REGISTERED: 'NOT_REGISTERED',
    NOT_VALIDATED: 'NOT_VALIDATED'
});
export const REGISTRATION_STATUSES = Object.freeze({
    PENDING: 'PENDING', // Registration is in process and not finished
    COMPLETED: 'COMPLETED', // Registration is finished successfully and is ended
    CANCELLED: 'CANCELLED', // Registration is cancelled by user or by system and is ended
    EXPIRED: 'EXPIRED', // Registration is timed out for various reasons and is ended
    FAILED: 'FAILED' // Registration is marked as failed, can not be continued
});
export const FLOW_TYPE = Object.freeze({
    V1: 'V1',
    V2: 'V2',
    V3: 'V3'
});
export const REGISTRATION_SIGNING_STATUS = Object.freeze({
    // All form data has not yet been sent, signing not started. Registration status PENDING
    NOT_STARTED: 'NOT_STARTED',
    // All form data has been sent. Signing will now happen. Send frontend to /confirm-registration-data
    IN_PROGRESS: 'IN_PROGRESS',
    // Changed to this when signing is completed in finalizeSigning. Send frontend to /registration-signed
    COMPLETED: 'COMPLETED'
});
