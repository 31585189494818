<template>
  <additional-details
      :field-definitions="fieldDefinitions"
      :initial-values="calculatedInitialValues"
      :date-format="dateFormat"
      :channel="channel"
      :locale="language"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
      :is-last-step="true"
      :get-status="getStatus"
      :register="register"
  ></additional-details>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapState } from 'pinia'
import { useAppStore } from '@/store/app.store'
import AdditionalDetails from '@/views/common/AdditionalDetails.vue'
import RegistrationMixin from '../common/RegistrationMixin.vue'
import { useAnalyticsStore } from '@/store/analytics.store'

export default {
  name: 'additional-details-fi',
  components: {
    AdditionalDetails
  },
  mixins: [
    RegistrationMixin
  ],
  data () {
    return {
      isLoading: true,
      dateFormat: 'D.M.YYYY',
      fieldDefinitions: [
        { name: 'fieldOfActivity' },
        { name: 'customFieldOfActivity' },
        {
          name: 'placeOfBirth',
          disablePrefill: true
        },
        {
          name: 'taxResidency',
          disablePrefill: true
        },
        {
          name: 'taxIdNumber',
          showField: form => {
            return form.taxResidency !== this.channel
          }
        },
        {
          name: 'citizenship',
          disablePrefill: true
        },
        { name: 'dualCitizenship' },
        { name: 'isPep' }
      ]
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language', 'formData']),
    ...mapState(useAnalyticsStore, ['trackingMetadata']),
    calculatedInitialValues () {
      return [
        { name: 'dateOfBirth', value: this.extractDateOfBirth(this.formData.personalIdentityCode), hidden: true }
      ]
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep', 'getStatus']),
    async register () {
      await this.registerFormData({ ...this.formData })
    },
    extractDateOfBirth (personalIdentityCode) {
      if (personalIdentityCode) {
        const centuryMap = {
          '+': '18',
          '-': '19',
          A: '20'
        }

        const day = personalIdentityCode.slice(0, 2)
        const month = personalIdentityCode.slice(2, 4)
        const yearSuffix = personalIdentityCode.slice(4, 6)
        const centuryCode = personalIdentityCode.charAt(6)
        const year = centuryMap[centuryCode] + yearSuffix

        return dayjs(`${day}.${month}.${year}`, 'DD.MM.YYYY', true).format(this.dateFormat)
      }

      return undefined
    }
  }
}
</script>
