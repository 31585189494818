<template>
  <personal-details
      :field-definitions="fieldDefinitions"
      :initial-values="prefilledInitialValues"
      :phone-number-prefix="phoneNumberPrefix"
      :phone-number-prefix-choice="phoneNumberPrefixChoice"
      :channel="channel"
      :locale="language"
      :translations-overrides="translations"
      :update-form-data="updateFormData"
      :go-to-next-step="goToNextStep"
      :personal-identity-code-mask="personalIdentityCodeMask"
      :enable-mandatory="true"
  ></personal-details>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import PersonalDetails from '@/views/common/PersonalDetails.vue'
import { cleanInput } from '@/views/common/util'
import { EEPersonalId } from '@bigbank/dc-common/validators/ee/EEPersonalId.class'
import errorHandler from '@/errors/errorHandler'
import { useAppStore } from '@/store/app.store'

const eePhoneValidator = require('@bigbank/dc-common/validators/ee/phoneNumber')

export default {
  name: 'personal-details-ee-current-account',
  components: {
    PersonalDetails
  },
  data () {
    return {
      isLoading: true,
      phoneNumberPrefix: '+372',
      phoneNumberPrefixChoice: ['+370', '+372', '+371'],
      personalIdentityCodeMask: '11111111111',
      hasAcademicTitle: false,
      marketingConsentVisible: false,
      fieldDefinitions: [
        { name: 'firstName' },
        { name: 'surname' },
        {
          name: 'personalIdentityCode',
          validator: {
            message: this.$pgettext(
              'customer_data_sheet',
              'Please insert valid ID number'
            ),
            validate: (value) => new EEPersonalId(value).isValid()
          }
        },
        { name: 'preferredContactLanguage' },
        { name: 'email' },
        {
          name: 'phoneNumber',
          validator: {
            message: this.$pgettext(
              'customer_data_sheet',
              'Please insert valid phone number'
            ),
            validate: (value) =>
              eePhoneValidator(cleanInput(value), this.channel)
          }
        },
        { name: 'marketingConsent' },
        { name: 'dataProcessingSilentConsent' }
      ]
    }
  },
  async created () {
    try {
      this.fillPhoneNumberPrefixes()
    } catch (err) {
      await errorHandler(err)
    }
  },
  computed: {
    ...mapState(useAppStore, ['channel', 'language', 'status', 'countries']),
    translations () {
      return {
        subtitle: this.$pgettext('customer_data_sheet', 'Please double check all your Personal Information. In case of any corrections, please edit.'),
        header: this.$pgettext('customer_data_sheet', 'Personal Details'),
        dataProcessingSilentConsentText: this.$pgettext('customer_data_sheet', 'DATA_PROCESSING_SILENT_CONSENT_TEXT [terms_link_text](http://terms_link_url)')
      }
    },
    prefilledInitialValues () {
      const values = [
        {
          name: 'preferredContactLanguage',
          value: this.status.customer?.preferredContactLanguage || this.language?.toUpperCase() || 'ET'
        },
        {
          name: 'email',
          value: this.status.customer?.email
        },
        {
          name: 'marketingConsent',
          value: this.status.customer?.marketingConsent
        }
      ]
      if (this.status.customer?.phoneNumberPrefix && this.status.customer?.phoneNumber) {
        values.push({
          name: 'fullPhoneNumber',
          value: `${this.status.customer?.phoneNumberPrefix} ${this.status.customer?.phoneNumber}`
        })
      }
      return values
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateFormData', 'goToNextStep', 'getCountries']),
    async fillPhoneNumberPrefixes () {
      await this.getCountries()

      this.phoneNumberPrefixChoice = this.countries
        .filter((country) => country.areaCodeAllowed)
        .map((country) => country.areaCode.replace(' ', ''))
        .sort((a, b) => (a > b ? 1 : -1))
      this.phoneNumberPrefixChoice = Array.from(
        new Set(this.phoneNumberPrefixChoice)
      )
    }
  }
}
</script>
