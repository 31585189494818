<template>
  <div>
    <bb-application-page
      :form-props="{
        uppercaseButtons: false,
        errorText: translations.errors.errorText,
        submitText: translations.submitText,
        disabled: isLoading,
        loading: isLoading
      }"
      @submit="onSubmit"
    >
      <bb-public-header
          #header
          :title="translations.header"
          icon="client-personal"
          :subtitle="translations.subtitle"
      />
      <p v-if="errorMessage" class="f-color-red f-align-center f-gotham f-small m-t-10 m-b-20">
        {{errorMessage}}
      </p>
      <div class="formOrder" ref="form">
        <div :style="orderStyle('personTitle')">
          <bb-radio
            v-if="showField('personTitle')"
            v-model="form.personTitle"
            v-validate="getValidationRules('personTitle')"
            :options="personTitleOptions"
            :label="translations.personTitle"
            :data-vv-as="translations.personTitle"
            name="personTitle"
            :mandatory="enableMandatory && !!getValidationRules('personTitle').required"
          />
        </div>
        <div :style="orderStyle('academicTitle')">
          <bb-checkbox
            v-if="showField('academicTitle')"
            v-model="hasAcademicTitle"
            :label="translations.addAcademicTitle"
            :data-vv-as="translations.addAcademicTitle"
            name="addAcademicTitle"
            background
            class="p-t-30"
          >
            {{translations.addAcademicTitle}}
          </bb-checkbox>
          <bb-select
            v-if="hasAcademicTitle && showField('academicTitle')"
            v-model="form.academicTitle"
            :options="academicTitleOptions"
            :label="translations.academicTitle"
            name="academicTitle"
          />
        </div>
        <div :style="orderStyle('companyName')">
          <bb-input
            v-if="showField('companyName')"
            v-model="form.companyName"
            v-validate="getValidationRules('companyName')"
            :label="translations.companyName"
            :data-vv-as="translations.companyName"
            name="companyName"
            :mandatory="enableMandatory && !!getValidationRules('companyName').required"
          />
        </div>
        <div :style="orderStyle('companyRegistrationCode')">
          <bb-input
            v-if="showField('companyRegistrationCode')"
            v-model="form.companyRegistrationCode"
            v-validate="getValidationRules('companyRegistrationCode')"
            :label="translations.companyRegistrationCode"
            :data-vv-as="translations.companyRegistrationCode"
            name="companyRegistrationCode"
            :mandatory="enableMandatory && !!getValidationRules('companyRegistrationCode').required"
          />
        </div>
        <div :style="orderStyle('businessActivityCode')">
          <bb-masked-input
            v-if="showField('businessActivityCode')"
            v-model="form.businessActivityCode"
            v-validate="getValidationRules('businessActivityCode')"
            :label="translations.businessActivityCode"
            :data-vv-as="translations.businessActivityCode"
            name="businessActivityCode"
            :mask="'1111'"
            :mandatory="enableMandatory && !!getValidationRules('businessActivityCode').required"
          />
        </div>
        <div :style="orderStyle('bankAccountNumber')">
          <bb-input
            v-if="showField('bankAccountNumber')"
            v-model="form.bankAccountNumber"
            v-validate="getValidationRules('bankAccountNumber')"
            :label="translations.companyBankAccount"
            :data-vv-as="translations.companyBankAccount"
            name="bankAccountNumber"
            :mandatory="enableMandatory && !!getValidationRules('bankAccountNumber').required"
          />
        </div>
        <div :style="orderStyle('firstName')">
          <bb-input
            v-if="showField('firstName')"
            v-model="form.firstName"
            v-validate="getValidationRules('firstName')"
            :disabled="isDisabled('firstName')"
            :label="translations.firstName"
            :data-vv-as="translations.firstName"
            name="firstName"
            :mandatory="enableMandatory && !!getValidationRules('firstName').required"
          />
        </div>
        <div :style="orderStyle('surname')">
          <bb-input
            v-if="showField('surname')"
            v-model="form.surname"
            v-validate="getValidationRules('surname')"
            :disabled="isDisabled('surname')"
            :label="translations.surname"
            :data-vv-as="translations.surname"
            name="surname"
            :mandatory="enableMandatory && !!getValidationRules('surname').required"
          />
        </div>
        <div :style="orderStyle('personalIdentityCode')">
          <bb-masked-input
            v-if="showField('personalIdentityCode') && hasPersonalIdentityCodeMask"
            :disabled="isDisabled('personalIdentityCode')"
            v-model="form.personalIdentityCode"
            v-validate="getValidationRules('personalIdentityCode')"
            :label="translations.idNumber"
            :data-vv-as="translations.idNumber"
            :mask="personalIdentityCodeMask"
            name="personalIdentityCode"
            inputmode="numeric"
            :mandatory="enableMandatory && !!getValidationRules('personalIdentityCode').required"
          />
          <bb-input
            v-if="showField('personalIdentityCode') && !hasPersonalIdentityCodeMask"
            :disabled="isDisabled('personalIdentityCode')"
            v-model="form.personalIdentityCode"
            v-validate="getValidationRules('personalIdentityCode')"
            :label="translations.idNumber"
            :data-vv-as="translations.idNumber"
            name="personalIdentityCode"
            inputmode="numeric"
            :mandatory="enableMandatory && !!getValidationRules('personalIdentityCode').required"
          />
        </div>
        <div :style="orderStyle('dateOfBirth')">
          <bb-input
            v-if="showField('dateOfBirth')"
            v-model="form.dateOfBirth"
            v-validate="getValidationRules('dateOfBirth')"
            :placeholder="dateFormatPlaceholder"
            :label="translations.dateOfBirth"
            :data-vv-as="translations.dateOfBirth"
            name="dateOfBirth"
            :mandatory="enableMandatory && !!getValidationRules('dateOfBirth').required"
          />
        </div>
        <div :style="orderStyle('preferredContactLanguage')">
          <bb-select
            v-if="showField('preferredContactLanguage')"
            v-model="form.preferredContactLanguage"
            v-validate="getValidationRules('preferredContactLanguage')"
            :options="contactLanguageOptions"
            :label="translations.preferredContactLanguage"
            :data-vv-as="translations.preferredContactLanguage"
            name="preferredContactLanguage"
            :mandatory="enableMandatory && !!getValidationRules('preferredContactLanguage').required"
            noempty
          />
        </div>
        <div :style="orderStyle('email')">
          <bb-input
            v-if="showField('email')"
            v-model="form.email"
            v-validate="getValidationRules('email')"
            :label="translations.email"
            :data-vv-as="translations.email"
            name="email"
            :mandatory="enableMandatory && !!getValidationRules('email').required"
          />
        </div>
        <div :style="orderStyle('phoneNumber')">
          <bb-prefixed-input
            v-if="showField('phoneNumber')"
            name="phoneNumber"
            @input="onPhoneNumberChange"
            v-validate="getValidationRules('phoneNumber')"
            v-model="form.fullPhoneNumber"
            type="tel"
            :separator="phoneNumberSeparator"
            disableSearch
            :prefixes="phoneNumberPrefixChoice.length === 0 ? [phoneNumberPrefix] : phoneNumberPrefixChoice"
            :label="translations.phoneNumber"
            :data-vv-as="translations.phoneNumber"
            :prefix="phoneNumberPrefix"
            :helpText="translations.phoneHelpText"
            :helpActive="true"
            :formatter="formatterAllowNumericOnly"
            :mandatory="enableMandatory && !!getValidationRules('phoneNumber').required"
          />
        </div>
        <div :style="orderStyle('phonePassword')">
          <bb-input
            v-if="showField('phonePassword')"
            v-model="form.phonePassword"
            v-validate="getValidationRules('phonePassword')"
            name="phonePassword"
            :label="translations.phonePassword"
            :data-vv-as="translations.phonePassword"
            :helpText="translations.phonePasswordHelpText"
            :helpActive="true"
            :mandatory="enableMandatory && !!getValidationRules('phonePassword').required"
          />
        </div>
        <div
          v-if="showField('externalRegistryCheckConsent')"
          :style="orderStyle('externalRegistryCheckConsent')"
        >
          <bb-checkbox
            v-model="form.externalRegistryCheckConsent"
            v-validate="'required'"
            :label="translations.externalRegistryCheckConsent"
            :data-vv-as="translations.externalRegistryCheckConsent"
            name="externalRegistryCheckConsent"
            background
            class="p-t-30"
          >
            {{translations.externalRegistryCheckConsentText}}
          </bb-checkbox>
          <p class="f-align-center f-gotham f-small p-t-10">
            <bb-link @click="externalRegistryCheckConsentVisible = true">
              {{translations.marketingConsentReadMore}}
            </bb-link>
          </p>
        </div>
        <div
          v-if="showField('marketingConsent')"
          :style="orderStyle('marketingConsent')"
        >
          <bb-checkbox
            v-model="form.marketingConsent"
            :label="marketingConsentText"
            :data-vv-as="marketingConsentText"
            name="marketingConsent"
            background
            class="p-t-30"
          >
            {{ marketingConsentText }}
          </bb-checkbox>
          <p class="f-align-center f-gotham f-small p-t-10">
            <bb-link @click="marketingConsentVisible = true">
              {{translations.marketingConsentReadMore}}
            </bb-link>
          </p>
        </div>
        <div :style="orderStyle('')">
          <text-with-links
            v-if="showField('formConfirmationText')"
            :text="translations.formConfirmationText"
            class="f-align-center f-gotham f-smallest p-t-30"
            :links="['http://www.bigbank.eu']"
          />
          <text-with-links
            v-if="showField('crossBorderConsent')"
            :text="translations.crossBorderConsentText"
            class="f-align-center f-gotham f-smallest p-t-30"
            :links="['http://www.bigbank.eu', 'http://www.bigbank.eu', 'http://www.bigbank.eu']"
          />
          <text-with-links
            v-if="showField('dataProcessingSilentConsent')"
            :text="dataProcessingSilentConsentText"
            class="f-align-center f-gotham f-smallest p-t-30"
            :links="['http://www.bigbank.eu']"
          />
          <text-with-links
            v-if="showField('externalRegistryCheckSilentConsent')"
            :text="translations.externalRegistryCheckSilentConsentText"
            class="f-align-center f-gotham f-smallest p-t-30"
            :links="['http://www.bigbank.eu']"
          />
          <text-with-links
            v-if="showField('selfserviceTermsConfirmation')"
            :text="computedSelfserviceTermsConfirmationText"
            class="f-align-center f-gotham f-smallest p-t-30"
            :links="['http://www.bigbank.eu']"
          />
        </div>
      </div>
    </bb-application-page>
    <bb-modal
      flavor="user"
      :visible="externalRegistryCheckConsentVisible"
      size="x2"
      centerVertically
      @close="externalRegistryCheckConsentVisible = false"
    >
      <text-with-links
        :text="computedExternalRegistryCheckConsentReadMoreFull"
        class="f-gotham f-small"
        :links="[]"
      />
    </bb-modal>
    <bb-modal
      flavor="user"
      :visible="marketingConsentVisible"
      size="x2"
      centerVertically
      @close="marketingConsentVisible = false"
    >
      <text-with-links
        :text="marketingConsentReadMoreFull"
        class="f-gotham f-small"
        :links="[]"
      />
    </bb-modal>
  </div>
</template>

<script>
import {
  BbApplicationPage,
  BbPublicHeader,
  BbPrefixedInput,
  BbInput,
  BbMaskedInput,
  BbCheckbox,
  BbLink,
  BbRadio,
  BbModal,
  BbSelect
} from '@bigbank/interface-components'
import { CountryChannel } from '@bigbank/dc-common/config'
import TextWithLinks from '@/components/TextWithLinks.vue'
import RegistrationMixin from '@/views/common/RegistrationMixin.vue'
import { mapState } from 'pinia'
import { cleanInput } from '@/views/common/util'
import dayjs from 'dayjs'
import { Validator, Rules } from 'vee-validate'
import { CUSTOMER_TYPE } from '@/const'
import { useAppStore } from '@/store/app.store'

export default {
  name: 'personal-details',
  components: {
    BbApplicationPage,
    BbPublicHeader,
    BbPrefixedInput,
    BbInput,
    BbMaskedInput,
    BbCheckbox,
    BbLink,
    BbRadio,
    BbModal,
    BbSelect,
    TextWithLinks
  },
  mixins: [
    RegistrationMixin
  ],
  props: {
    /**
     * Example:
     * [
     *   {
     *     name: 'taxIdNumber',
     *     showField: form => form.taxResidency !== this.channel
     *     validator: { message: 'Wrong value', validate: value => {...} }
     *   }
     * ]
     *
     * Field names:
     *   dateOfBirth, placeOfBirth, taxIdNumber, taxResidency,
     *   fieldOfActivity, customFieldOfActivity, citizenship,
     *   dualCitizenship, isPep
     */

    translationsOverrides: {
      type: Object,
      required: false
    },
    fieldDefinitions: {
      type: Array,
      required: true
    },
    dateFormat: {
      type: [String, Array],
      required: false,
      default: 'YYYY-MM-DD'
    },
    phoneNumberPrefix: {
      type: String,
      required: true
    },
    phoneNumberPrefixChoice: {
      type: Array,
      default: () => [],
      required: false
    },
    channel: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    personalIdentityCodeMask: {
      type: String,
      required: false,
      default: ''
    },
    updateFormData: {
      type: Function,
      required: true
    },
    goToNextStep: {
      type: Function,
      required: true
    },
    enableMandatory: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      form: {
        personTitle: undefined,
        academicTitle: undefined,
        personalIdentityCode: undefined,
        firstName: undefined,
        surname: undefined,
        phoneNumberPrefix: undefined,
        phoneNumber: undefined,
        fullPhoneNumber: undefined,
        phonePassword: undefined,
        marketingConsent: null,
        externalRegistryCheckConsent: null,
        email: undefined,
        dateOfBirth: undefined,
        preferredContactLanguage: undefined
      },
      hasAcademicTitle: false,
      marketingConsentVisible: false,
      externalRegistryCheckConsentVisible: false,
      phoneNumberSeparator: ' '
    }
  },
  watch: {
    hasAcademicTitle (hasTitle) {
      if (!hasTitle) {
        this.form.academicTitle = undefined
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['featureFlags', 'status', 'customerType', 'formData', 'isV2Flow', 'isV3Flow']),
    dateFormatPlaceholder () {
      if (Array.isArray(this.dateFormat)) {
        return this.dateFormat[0]
      }

      return this.dateFormat
    },
    dataProcessingSilentConsentText () {
      return this.channel === 'LV'
        ? this.translations.dataProcessingSilentConsentTextLv
        : this.translations.dataProcessingSilentConsentText
    },
    marketingConsentText () {
      return this.channel === 'LV'
        ? this.translations.marketingConsentTextLv
        : this.translations.marketingConsentText
    },
    marketingConsentReadMoreFull () {
      return this.channel === 'LV'
        ? this.translations.marketingConsentReadMoreFullLv
        : this.translations.marketingConsentReadMoreFull
    },
    crossBorderConsentText () {
      switch (this.channel.toUpperCase()) {
        case 'AT':
          return this.$pgettext('registration', 'CROSS_BORDER_CONSENT_TEXT_AT [link1_text](http://link1) [link2_text](http://link2) [link3_text](http://link3)')
        case 'DE':
        case 'NL':
        default:
          return this.$pgettext('registration', 'CROSS_BORDER_CONSENT_TEXT [link1_text](http://link1) [link2_text](http://link2) [link3_text](http://link3)')
      }
    },
    phoneHelpText () {
      switch (this.channel.toUpperCase()) {
        case 'AT':
          return this.$pgettext('registration', 'PHONE_TOOLTIP_HELP_TEXT_AT')
        case 'DE':
        case 'NL':
        default:
          return this.$pgettext('registration', 'PHONE_TOOLTIP_HELP_TEXT')
      }
    },
    computedExternalRegistryCheckConsentReadMoreFull () {
      return this.channel === 'LV'
        ? this.translations.externalRegistryCheckConsentReadMoreFullLv
        : this.translations.externalRegistryCheckConsentReadMoreFull
    },
    computedSelfserviceTermsConfirmationText () {
      return this.channel === 'LV'
        ? this.translations.selfserviceTermsConfirmationTextLv
        : this.translations.selfserviceTermsConfirmationText
    },
    translations () {
      return {
        header: this.$pgettext('registration', 'Personal Details'),
        subtitle: this.$pgettext('registration', 'Please fill this form below to become our Customer. If You already are a Customer, log into Self-Service or contact our Customer Service.'),
        personTitle: this.$pgettext('registration', 'Title'),
        addAcademicTitle: this.$pgettext('registration', "I'd like to add my academic title."),
        academicTitle: this.$pgettext('registration', 'Academic title'),
        companyName: this.$pgettext('registration', 'Company name'),
        companyRegistrationCode: this.$pgettext('registration', 'Company registration code'),
        businessActivityCode: this.$pgettext('registration', 'Business activity code'),
        companyBankAccount: this.$pgettext('registration', 'Company bank account'),
        firstName: this.$pgettext('registration', 'First name'),
        surname: this.$pgettext('registration', 'Surname'),
        dateOfBirth: this.$pgettext('registration', 'Date of Birth'),
        email: this.$pgettext('registration', 'E-mail'),
        submitText: this.$pgettext('registration', 'Continue'),
        idNumber: this.$pgettext('registration', 'ID number'),
        phoneNumber: this.$pgettext('registration', 'Phone number'),
        phonePassword: this.$pgettext('registration', 'Phone password'),
        marketingConsent: this.$pgettext('registration', 'Marketing consent'),
        marketingConsentText: this.$pgettext('registration', 'I would like to receive future offers from Bigbank.'),
        marketingConsentTextLv: this.$pgettext('registration_lv', 'I would like to receive future offers from Bigbank.'),
        marketingConsentReadMoreFull: this.$pgettext('registration', 'MARKETING_CONSENT_MODAL_TEXT [link_text](http://link_url)'),
        marketingConsentReadMoreFullLv: this.$pgettext('registration_lv', 'MARKETING_CONSENT_MODAL_TEXT [link_text](http://link_url)'),
        externalRegistryCheckConsent: this.$pgettext('registration', 'External consent'),
        externalRegistryCheckConsentText: this.$pgettext('registration', 'I agree to external registry check.'),
        externalRegistryCheckSilentConsentText: this.$pgettext('registration', 'EXTERNAL_REGISTRY_CHECK_SILENT_CONSENT_TEXT [terms_link_text](http://terms_link_url)'),
        externalRegistryCheckConsentReadMoreFull: this.$pgettext('registration', 'EXTERNAL_REGISTRY_CHECK_CONSENT_MODAL_TEXT [link_text](http://link_url)'),
        externalRegistryCheckConsentReadMoreFullLv: this.$pgettext('registration_lv', 'EXTERNAL_REGISTRY_CHECK_CONSENT_MODAL_TEXT [link_text](http://link_url)'),
        selfserviceTermsConfirmationText: this.$pgettext('registration', 'SELFSERVICE_CONSENT_TEXT [terms_link_text](http://terms_link_url)'),
        selfserviceTermsConfirmationTextLv: this.$pgettext('registration_lv', 'SELFSERVICE_CONSENT_TEXT [terms_link_text](http://terms_link_url)'),
        marketingConsentReadMore: this.$pgettext('registration_marketing_consent', 'Read more'),
        phoneHelpText: this.phoneHelpText,
        phonePasswordHelpText: this.$pgettext('registration', 'PHONE_PASSWORD_TOOLTIP_HELP_TEXT'),
        crossBorderConsentText: this.crossBorderConsentText,
        formConfirmationText: this.$pgettext('registration', 'FORM_CONFIRMATION_TEXT [terms_link_text](http://terms_link_url)'),
        dataProcessingSilentConsentText: this.$pgettext('registration', 'DATA_PROCESSING_SILENT_CONSENT_TEXT [terms_link_text](http://terms_link_url)'),
        dataProcessingSilentConsentTextLv: this.$pgettext('registration_lv', 'DATA_PROCESSING_SILENT_CONSENT_TEXT [terms_link_text](http://terms_link_url)'),
        errors: {
          errorText: this.$pgettext('registration', 'FORM_HAS_ERRORS_MESSAGE'),
          invalidIdNumber: this.$pgettext('registration', 'Please insert valid ID number'),
          invalidPhoneNumber: this.$pgettext('registration', 'Please insert valid phone number'),
          invalidDateFormat: this.$pgettext('registration', 'Invalid date format'),
          invalidEmail: this.$pgettext('registration', 'Please insert valid email'),
          invalidEmailLv: this.$pgettext('registration_lv', 'Please insert valid email'),
          invalidBusinessActivityCode: this.$pgettext('registration', 'Please insert valid business activity code'),
          INTERNAL_ERROR: this.$pgettext('registration_error', 'Something went wrong, please try again later. If the error persists, please contact as an provide the following error code: #CODE#.'),
          VALIDATION_FAILED: this.$pgettext('registration_error', 'Form validation failed, please double check your data and try again.')
        },
        preferredContactLanguage: this.$pgettext('customer_data_sheet', 'Preferred contact language'),
        languages: {
          ET: this.$pgettext('customer_data_sheet', 'Estonian'),
          RU: this.$pgettext('customer_data_sheet', 'Russian')
        },
        ...this.translationsOverrides
      }
    },
    hasPersonalIdentityCodeMask () {
      return (this.personalIdentityCodeMask ?? '').length > 0
    },
    personTitleOptions () {
      return [{
        value: 'Mr',
        text: this.$pgettext('registration', 'Mr')
      }, {
        value: 'Mrs',
        text: this.$pgettext('registration', 'Mrs')
      }]
    },
    academicTitleOptions () {
      return [
        {
          text: 'Dr.',
          value: 'Dr.'
        },
        {
          text: 'Prof.',
          value: 'Prof.'
        },
        {
          text: 'Prof. Dr',
          value: 'Prof. Dr'
        }
      ]
    },
    isDateOfBirthEnabledInPersonalDetails () {
      return this.channel === CountryChannel.LV && (this.isV2Flow || this.customerType === CUSTOMER_TYPE.Legal)
    },
    commonInitialValues () {
      const customer = this.status?.customer

      return [
        {
          name: 'firstName',
          value: customer?.firstName,
          isDisabled: !!(this.isV3Flow && customer?.firstName)
        },
        {
          name: 'surname',
          value: customer?.surname,
          isDisabled: !!(this.isV3Flow && customer?.surname)
        },
        {
          name: 'personalIdentityCode',
          value: customer?.personalIdentityCode,
          isDisabled: !!((this.isV2Flow || this.isV3Flow) && customer?.personalIdentityCode)
        }
      ]
    },
    contactLanguageOptions () {
      if (this.isV3Flow && this.channel === 'EE') {
        return [{
          text: this.translations.languages.ET,
          value: 'ET'
        }, {
          text: this.translations.languages.RU,
          value: 'RU'
        }]
      } else {
        return []
      }
    }
  },
  methods: {
    formatterAllowNumericOnly (inputStr) {
      return inputStr ? inputStr.replace(/\D/g, '') : ''
    },
    onPhoneNumberChange (input) {
      // Fix field phone number field going invalid on page load
      if (this.form.phoneNumber === undefined && input.trim() === this.phoneNumberPrefix) {
        const phoneNumberField = this.$validator.fields.find({ name: 'phoneNumber' })
        this.$validator.reset(phoneNumberField)
        return
      } else if (this.form.fullPhoneNumber === input) {
        // Trigger validation after $validator.reset to fix current account prefilled number
        this.$nextTick(() => {
          this.$validator.validateAll()
        })
      }

      const [prefix, phoneNumber] = input.split(this.phoneNumberSeparator)
      this.form.phoneNumber = cleanInput((phoneNumber || '').trim())
      this.form.phoneNumberPrefix = prefix
    },
    async submitAfterHCaptchaVerify (hCaptchaToken) {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fullPhoneNumber, ...form } = this.form
        const personalDetails = {
          ...form,
          marketingConsent: !!form.marketingConsent
        }

        if (this.isDateOfBirthEnabledInPersonalDetails && personalDetails.dateOfBirth) {
          personalDetails.dateOfBirth = dayjs(personalDetails.dateOfBirth, this.dateFormat, true).format('YYYY-MM-DD')
        } else if (!this.isDateOfBirthEnabledInPersonalDetails) {
          delete personalDetails.dateOfBirth
        }

        await this.updateFormData({ personalDetails })
        await this.partialRegistration(this.formData, hCaptchaToken)
        await this.goToNextStep()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    }
  },
  created () {
    this.addValidatorRules()

    this.$validator.extend('date_format', {
      getMessage: () => this.translations.errors.invalidDateFormat,
      validate: date => {
        const dayJsDate = dayjs(date, this.dateFormat, true)
        return dayJsDate && dayJsDate.isValid() && dayJsDate.isBefore(dayjs())
      }
    })

    this.$validator.extend('business_activity_code', {
      getMessage: () => this.translations.errors.invalidBusinessActivityCode,
      validate: code => {
        return code && code.replace('_', '').length === 4
      }
    })

    this.isLoading = false
    Validator.extend('email-extended', {
      ...Rules.email,
      getMessage: () => this.channel === 'LV' ? this.translations.errors.invalidEmailLv : this.translations.errors.invalidEmail
    })
  }
}
</script>

<style lang="scss" scoped>
  .formOrder {
    display: flex;
    flex-direction: column;
  }
</style>
