<template>
  <bb-application-page
    :form-props="{
      uppercaseButtons: true,
      errorText: translations.errorText,
      submitText: translations.submitText,
      disabled: isLoading,
      loading: isLoading,
    }"
    @submit="onSubmit"
  >
    <bb-public-header
      #header
      :title="translations.header"
      icon="client-personal"
      :subtitle="translations.subtitle"
    />
    <p v-if="errorMessage" class="f-color-red f-align-center f-gotham f-small m-t-10 m-b-20">
      {{errorMessage}}
    </p>
    <bb-masked-input
      mask="1111111111"
      v-model="form.personalIdentityCode"
      v-validate="'required|bg_id_number'"
      :label="translations.idNumber"
      :data-vv-as="translations.idNumber"
      name="personalIdentityCode"
      inputmode="numeric"
    />
    <bb-prefixed-input
      name="phoneNumber"
      v-model="form.phoneNumber"
      v-validate="'required|bg_phone'"
      :label="translations.phoneNumber"
      :data-vv-as="translations.phoneNumber"
      type="tel"
      :prefixes="prefixes"
      :helpText="translations.phoneHelpText"
      :helpActive="true"
      :formatter="formatterForBgPhoneNumber"
    />
    <bb-select
      v-model="form.activityType"
      v-validate="'required'"
      name="activityType"
      :options="activityTypeOptions"
      :label="translations.activityType"
      :data-vv-as="translations.activityType"
    />
    <bb-input
      v-if="showActivityTypeDescription"
      v-model="form.activityTypeDescription"
      v-validate="'required'"
      name="activityTypeDescription"
      :label="translations.activityTypeDescription"
      :data-vv-as="translations.activityTypeDescription"
    />
    <bb-checkbox
      v-model="hasDualCitizenship"
      name="hasDualCitizenship"
      :label="translations.informationConsent"
      :data-vv-as="translations.informationConsent"
      class="p-t-30"
    >
      {{ translations.dualCitizenshipText }}
    </bb-checkbox>
    <bb-select
      v-if="hasDualCitizenship"
      v-model="form.dualCitizenCountryCode"
      v-validate="'required'"
      name="dualCitizenship"
      :options="countryOptions"
      :label="translations.dualCitizenship"
      :data-vv-as="translations.dualCitizenship"
    />
    <bb-select
      v-if="isDeposit"
      v-model="form.taxResidency"
      v-validate="'bg_tax_residency'"
      :options="countryOptionsWithExcludedStateless"
      :label="translations.taxResidency"
      :data-vv-as="translations.taxResidency"
      :helpActive="true"
      :helpText="translations.taxResidencyHelp"
      name="taxResidency"
      @change="onTaxResidencyChange"
    />
    <bb-input
      v-if="showTaxIdNumberField"
      v-model="form.taxIdNumber"
      v-validate="'bg_tax_id_number'"
      :label="translations.taxIdNumber"
      :data-vv-as="translations.taxIdNumber"
      name="taxIdNumber"
    />
    <bb-radio
      class="p-t-30"
      v-model="form.isPep"
      :options="pepOptions"
      :label="translations.pep"
      :data-vv-as="translations.pep"
      name="isPep"
      v-validate="'required'"
      :helpText="translations.pepHelpText"
      :helpActive="true"
    />
    <bb-textarea
      class="is-pep-reason"
      v-if="isPep"
      v-model="form.isPepReason"
      v-validate="'required'"
      :label="translations.isPepReason"
      :data-vv-as="translations.isPepReason"
      name="isPepReason"
      framed
    />
    <bb-checkbox
      v-model="form.marketingConsent"
      :label="translations.marketingConsent"
      :data-vv-as="translations.marketingConsent"
      name="marketingConsent"
      background
      class="p-t-30"
    >
      {{translations.marketingConsentText}}
    </bb-checkbox>
    <p class="f-align-center f-gotham f-small p-t-10">
      <bb-link @click="marketingConsentVisible = true">{{translations.marketingConsentReadMore}}</bb-link>
    </p>
    <text-with-links
      :text="translations.formConfirmationText"
      class="f-align-center f-gotham f-smallest p-t-30"
      :links="['http://www.bigbank.eu']"
    />
    <text-with-links
      :text="translations.gtcConfirmationText"
      class="f-align-center f-gotham f-smallest p-t-30"
      :links="['http://www.bigbank.eu']"
    />
    <text-with-links
      :text="translations.evrotrustConfirmationText"
      class="f-align-center f-gotham f-smallest p-t-30"
      :links="['http://www.bigbank.eu']"
      @click="evrotrustConfirmationVisible = true"
    />
    <text-with-links
      :text="translations.selfserviceTermsConfirmationText"
      class="f-align-center f-gotham f-smallest p-t-30"
      :links="['http://www.bigbank.eu']"
    />
    <bb-modal flavor="user" :visible="marketingConsentVisible" @close="marketingConsentVisible = false" size="x2" centerVertically>
      <text-with-links
        :text="translations.marketingConsentReadMoreFull"
        class="f-gotham f-small"
        :links="[]"
      />
    </bb-modal>
    <bb-modal flavor="user" :visible="evrotrustConfirmationVisible" @close="evrotrustConfirmationVisible = false" size="x2" centerVertically>
      <text-with-links
        :text="translations.evrotrustConfirmationReadMoreFull"
        class="f-gotham f-small"
        :links="[]"
      />
    </bb-modal>
  </bb-application-page>
</template>

<script>
import {
  BbApplicationPage,
  BbPublicHeader,
  BbPrefixedInput,
  BbInput,
  BbMaskedInput,
  BbSelect,
  BbTextarea,
  BbCheckbox,
  BbLink,
  BbRadio,
  BbModal
} from '@bigbank/interface-components'
import * as idNumberValidator from '@bigbank/dc-common/validators/bg/idNumber'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { getCountriesList } from '@/util/countries'
import errorHandler from './../../errors/errorHandler'
import RegistrationMixin from '../common/RegistrationMixin.vue'
import TextWithLinks from './../../components/TextWithLinks.vue'
import { PRODUCT_TYPE, STATELESS_COUNTRY_CODE } from '@/const'
import { FormValidation, isCustomFieldOfActivityRequired, getFieldsOfActivityByChannel, isTaxResidencyFieldShownAndRequired, isTINRequired } from '@bigbank/dc-common/validators/validation.config'
import { CountryChannel, FieldOfActivity } from '@bigbank/dc-common/config'
import { useAppStore } from '@/store/app.store'
import bgStepsConfig from './steps.config'

const bgPhoneValidator = require('@bigbank/dc-common/validators/bg/phoneNumber')

export default {
  name: 'bg-registration',
  components: {
    BbApplicationPage,
    BbPublicHeader,
    BbPrefixedInput,
    BbInput,
    BbMaskedInput,
    BbSelect,
    BbTextarea,
    BbCheckbox,
    BbLink,
    BbRadio,
    BbModal,
    TextWithLinks
  },
  mixins: [
    RegistrationMixin
  ],
  data () {
    return {
      isLoading: false,
      hasDualCitizenship: false,
      form: {
        personalIdentityCode: undefined,
        phoneNumber: '+359 ',
        activityType: undefined,
        activityTypeDescription: undefined,
        taxResidency: undefined,
        taxIdNumber: undefined,
        marketingConsent: null,
        isPep: 'NO',
        isPepReason: undefined,
        dualCitizenCountryCode: undefined
      },
      prefixes: ['+359'],
      marketingConsentVisible: false,
      evrotrustConfirmationVisible: false
    }
  },
  computed: {
    ...mapState(useAppStore, ['featureFlags', 'language', 'productType', 'channel', 'countries']),
    ...mapWritableState(useAppStore, ['activeView']),
    showActivityTypeDescription () {
      return isCustomFieldOfActivityRequired(FormValidation.OnBoardingFlow, this.channel, this.form.activityType)
    },
    translations () {
      return {
        header: this.$pgettext('registration', 'HEADER'),
        subtitle: this.$pgettext('registration', 'SUBTITLE'),
        errorText: this.$pgettext('registration', 'FORM_HAS_ERRORS_MESSAGE'),
        submitText: this.$pgettext('registration', 'SUBMIT_FORM_BUTTON_TEXT'),
        idNumber: this.$pgettext('registration', 'ID number'),
        phoneNumber: this.$pgettext('registration', 'Phone number'),
        activityType: this.$pgettext('registration', 'Activity type'),
        activityTypeDescription: this.$pgettext('registration', 'Activity type description'),
        dualCitizenship: this.$pgettext('registration', 'Dualcitizenship'),
        dualCitizenshipText: this.$pgettext('registration', 'I have dualcitizenship'),
        taxIdNumber: this.$pgettext('registration', 'Tax ID Number'),
        taxResidency: this.$pgettext('registration', 'Tax Residency'),
        taxResidencyHelp: this.$pgettext('registration', 'TAX_RESIDENCY_HELP_TEXT'),
        pep: this.$pgettext('registration', 'Are you a PEP?'),
        isPepReason: this.$pgettext('registration', 'PEP Reason'),
        marketingConsent: this.$pgettext('registration', 'Marketing consent'),
        marketingConsentText: this.$pgettext('registration', 'MARKETING_CONSENT_TEXT'),
        marketingConsentReadMoreFull: this.$pgettext('registration', 'MARKETING_CONSENT_MODAL_TEXT [link_text](http://link_url)'),
        selfserviceTermsConfirmationText: this.$pgettext('registration', 'SELFSERVICE_CONSENT_TEXT [terms_link_text](http://terms_link_url)'),
        marketingConsentReadMore: this.$pgettext('registration_marketing_consent', 'Read more'),
        pepHelpText: this.$pgettext('registration', 'PEP_TOOLTIP_HELP_TEXT'),
        phoneHelpText: this.$pgettext('registration', 'PHONE_TOOLTIP_HELP_TEXT'),
        formConfirmationText: this.$pgettext('registration', 'FORM_CONFIRMATION_TEXT [terms_link_text](http://terms_link_url)'),
        gtcConfirmationText: this.$pgettext('registration', 'GTC_CONFIRMATION_TEXT [terms_link_text](http://terms_link_url)'),
        evrotrustConfirmationText: this.$pgettext('registration', 'EVROTRUST_CONFIRMATION_TEXT [terms_link_text](http://terms_link_url)'),
        evrotrustConfirmationReadMoreFull: this.$pgettext('registration', 'EVROTRUST_CONFIRMATION_TEXT_FULL [terms_link_text](http://terms_link_url)'),
        invalidIdNumber: this.$pgettext('registration', 'Please insert valid ID Number'),
        invalidPhoneNumber: this.$pgettext('registration', 'Please insert valid Phonenumber'),
        fieldsOfActivity: {
          [FieldOfActivity.PRIVATE_SECTOR]: this.$pgettext('registration', 'Private sector'),
          [FieldOfActivity.PUBLIC_SECTOR]: this.$pgettext('registration', 'Public sector'),
          [FieldOfActivity.ENTREPRENEUR]: this.$pgettext('registration', 'Entrepreneur'),
          [FieldOfActivity.INDIVIDUAL_ACTIVITY]: this.$pgettext('registration', 'Individual activity'),
          [FieldOfActivity.UNEMPLOYED]: this.$pgettext('registration', 'Unemployed'),
          [FieldOfActivity.PENSIONER]: this.$pgettext('registration', 'Pensioner'),
          [FieldOfActivity.STUDENT]: this.$pgettext('registration', 'Student'),
          [FieldOfActivity.PARENTAL_LEAVE]: this.$pgettext('registration', 'Parental leave'),
          [FieldOfActivity.CONSCRIPT]: this.$pgettext('registration', 'Conscript'),
          [FieldOfActivity.OTHER]: this.$pgettext('registration', 'Other')
        }
      }
    },
    pepOptions () {
      return [
        {
          value: 'NO',
          text: this.$pgettext('registration', 'PEP_NO')
        },
        {
          value: 'YES',
          text: this.$pgettext('registration', 'PEP_YES')
        }
      ]
    },
    isPep () {
      return this.form.isPep === 'YES'
    },
    activityTypeOptions () {
      return getFieldsOfActivityByChannel(FormValidation.OnBoardingFlow, this.channel).map(fieldOfActivity => ({
        text: this.translations.fieldsOfActivity[fieldOfActivity] ?? '',
        value: fieldOfActivity
      }))
    },
    countryOptionsWithExcludedStateless () {
      return getCountriesList(this.language, this.countries, [STATELESS_COUNTRY_CODE])
    },
    countryOptions () {
      return getCountriesList(this.language, this.countries)
    },
    selfserviceTermsConfirmationText () {
      return this.translations.selfserviceTermsConfirmationText
    },
    isDeposit () {
      return this.productType === PRODUCT_TYPE.Deposit
    },
    showTaxIdNumberField () {
      return this.form?.taxResidency?.toUpperCase() !== this.channel
    }
  },
  methods: {
    ...mapActions(useAppStore, ['getStatus', 'getCountries', 'getIdentificationData']),
    formatterForBgPhoneNumber (inputStr) {
      if (!inputStr) {
        return ''
      }

      return inputStr.replace(/^0+|\D/, '')
    },
    async onSubmit () {
      if (this.isLoading) {
        return false
      }
      this.isLoading = true
      try {
        await this.registerFormData({
          ...this.form,
          isPep: this.isPep,
          isPepReason: this.isPep ? this.form.isPepReason : undefined,
          phoneNumber: this.cleanInput(this.form.phoneNumber),
          dualCitizenCountryCode: (this.hasDualCitizenship && this.form.dualCitizenCountryCode) || undefined,
          marketingConsent: !!this.form.marketingConsent
        })
        await this.getStatus()
        await this.getIdentificationData()
      } catch (err) {
        this.handleError(err)
      } finally {
        this.isLoading = false
      }
    },
    onTaxResidencyChange () {
      this.$emit('tax-residency-change', this.form.taxResidency)
    }
  },
  async created () {
    this.$validator.extend('bg_id_number', {
      getMessage: () => this.translations.invalidIdNumber,
      validate: value => {
        return idNumberValidator(value)
      }
    })

    this.$validator.extend('bg_phone', {
      getMessage: () => this.translations.invalidPhoneNumber,
      validate: value => {
        return bgPhoneValidator(this.cleanInput(value), this.channel)
      }
    })

    this.$validator.extend('bg_tax_residency', {
      validate: value => {
        if (this.isDeposit && isTaxResidencyFieldShownAndRequired(FormValidation.OnBoardingFlow, CountryChannel.BG, null).required) {
          return typeof value === 'string' && value.length > 0
        }

        return true
      }
    })

    this.$validator.extend('bg_tax_id_number', {
      validate: value => {
        if (this.isDeposit && isTINRequired(FormValidation.OnBoardingFlow, CountryChannel.BG, this.form.taxResidency)) {
          return typeof value === 'string' && value.length > 0
        }

        return true
      }
    })

    try {
      this.activeView = bgStepsConfig.private[0]
      await this.getCountries()
      this.form.taxResidency = this.channel
    } catch (err) {
      errorHandler(err)
    }
  }
}
</script>

<style lang="scss">
.bb-textarea.is-pep-reason {
  label {
    white-space: normal !important;
    overflow: auto !important;
  }
}
</style>
